import { Autocomplete, AutocompleteRenderInputParams, Grid, SxProps, Theme } from '@mui/material';
import { InputComponent } from '../../components/inputComponents/InputComponent';
import TextFieldWrapper from '../TextFieldWrapper/TextFieldWrapper';
import { useState } from 'react';
import { FormikContextType } from 'formik';
import { AddPrizeState } from '../../types/componentTypes/prizeComponentTypes';

type DisableSelectedItemsDropdownProps = {
    inputComponentProps: {
        uniqueKey: string;
        value: string;
        label: string;
        className?: string;
        errorPath?: string;
        touchedErrorPath?: string;
        formikLabel: string;
        type?: 'string' | 'number';
        showError?: boolean;
        min?: number,
        max?: number;
    };
    dropDownProps: {
        currentItemIndex: number;
        className?: string;
        width?: number;
        fontSize?: number;
        label: string;
        placeholder: string;
        customLabelConstructor?: any,
        dropDownFieldPlaceholderText?: string,
        value: string;
        testId: string;
        uniqueKey: string;
        fullObjectOptionsList?: object[]|any[];
        disableClearableField?: boolean;
        optionsList: string[];
        selectedItems: string[];
        textFieldStyles: SxProps<Theme>;
        onChangeHandler: (props: {
            event: React.SyntheticEvent<Element, Event>;
            selectedItem: string;
            currentItemIndex: number;
        }) => void;
    };
    formik: FormikContextType<AddPrizeState> | unknown;
};

const DisableSelectedItemsDropdown = (props: DisableSelectedItemsDropdownProps) => {
    const [dropDownOpened, setDropDownOpened ] = useState<boolean>(false);
    const { formik, inputComponentProps, dropDownProps } = props;
    const inputType = inputComponentProps.type || 'string';

    return (
        <Grid container direction='row' spacing={0.75}>
            <Grid item xs={dropDownProps.width || 7.5} >
                <Autocomplete
                    sx={{
                        '& .MuiAutocomplete-input, & .MuiInputLabel-root': {
                            fontSize: dropDownProps.fontSize || 'inherit',
                        },
                    }}
                    key={dropDownProps.uniqueKey}
                    fullWidth
                    filterOptions={(options) => options}
                    className={dropDownProps.className}
                    value={dropDownProps.value || dropDownProps?.dropDownFieldPlaceholderText}
                    options={dropDownProps.optionsList}
                    onChange={(event, selectedItem) => {
                        dropDownProps.onChangeHandler({
                            event,
                            selectedItem,
                            currentItemIndex: dropDownProps.currentItemIndex,
                        });
                    }
                    }
                    onOpen={(event => {setDropDownOpened(true)})}
                    onClose={(event => {setDropDownOpened(false)})}
                    getOptionLabel={(option: string) => dropDownProps.customLabelConstructor ? dropDownProps.customLabelConstructor(option,dropDownProps.value, dropDownProps.fullObjectOptionsList, dropDownOpened) : option}
                    getOptionDisabled={(option: string) => dropDownProps.selectedItems.includes(option)}
                    disableClearable={dropDownProps.disableClearableField || true}
                    renderInput={(params: AutocompleteRenderInputParams) => (
                        <TextFieldWrapper
                            {...params}
                            sx={{ ...dropDownProps.textFieldStyles }}
                            data-testid={dropDownProps.testId}
                            label={dropDownProps.label}
                            placeholder={dropDownProps.placeholder}
                        />
                    )}
                />
            </Grid>
            <Grid item xs>
                <InputComponent
                    key={inputComponentProps.uniqueKey}
                    inputClassName={inputComponentProps.className}
                    formik={formik}
                    inputValue={inputComponentProps.value}
                    formikLabel={inputComponentProps.formikLabel}
                    label={inputComponentProps.label}
                    errorPath={inputComponentProps.errorPath}
                    touchedErrorPath={inputComponentProps.touchedErrorPath}
                    type={inputType}
                    shouldShowError={inputComponentProps.showError}
                    min={inputComponentProps.min}
                    max={inputComponentProps.max}
                />
            </Grid>
        </Grid >
    );
};

export default DisableSelectedItemsDropdown;
