import { ToolTipPlacement } from '../../../../types/componentTypes/tooltip';
import { PageErrors } from '../types/AdditionalDetailsTypes';

export const MIN_AGE = 12;
export const MAX_AGE = 150; // sensible max age default
export const PARTICIPATION_IDS_MAX = 100;
export const PARTICIPATION_IDS_MIN = 1;
export const PARTICIPATION_IDS_DEFAULT = 10;

const promoFlowCheckerLambdas = {
    minAge: ['age'],
};

export const flowMap = {
    queryVouchers: {
        flowLambdas: ['digitalCodesQueryByUserLambda'],
    },
    listPrizes: {
        flowLambdas: ['prizeQueryLambda'],
        params: { additionalInformation: true },
    },
    redeemPrize: {
        checkerLambdas: ['prizeCheckerLambda', 'currencyCheckerLambda'],
        flowLambdas: ['prizeRedeemLambda'],
    },
    addTransaction: {
        flowLambdas: ['transactionLambda'],
    },
    listTransactions: {
        flowLambdas: ["listTransactionsByUserLambda"]
    },
    redeemVoucher: {
        flowLambdas: ['digitalCodesRedeemLambda'],
    },
    queryWallet: {
        flowLambdas: ['walletLambda'],
    },
    redeemPincodeForCurrencies: {
        flowLambdas: ['burnPincodes', 'pincodeToCurrency', 'transactionLambda'],
    },
    acceptReservedVoucher: {
        flowLambdas: ['acceptReservedVoucherLambda'],
        params: { maxParticipationIds: PARTICIPATION_IDS_DEFAULT },
    },
    rejectReservedVoucher: {
        flowLambdas: ['rejectReservedVoucherLambda'],
    },
    rejectWinningMoment: {
        flowLambdas: ['rejectWinningMomentLambda'],
    },
} as const;

export const ToolTipProps = {
    baseProps: {
        styleName: 'details-checkbox-tooltip',
        textVariant: 'body1' as const,
        placement: ToolTipPlacement.RightEnd,
    },
    'Collect & Get'() {
        return {
            redeemPrize: {
                ...this.baseProps,
                text: 'Prize Redemption',
                dataTestId:'prize-redemption-tooltip',
                tooltip: 'Once enabled, participants can redeem prizes even if no vouchers are distributed',
            },
            addTransaction: {
                ...this.baseProps,
                text: 'Transactions',
                dataTestId:'transactions-tooltip',
                tooltip: "Once enabled, the amount of every participant's wallet can be modified",
            },
            listTransactions: {
                ...this.baseProps,
                text: 'List Transactions',
                tooltip: "Once enabled, the consumer's transactions can be listed.",
            },
            queryVouchers: {
                ...this.baseProps,
                text: 'Vouchers History',
                dataTestId:'vouchers-history-tooltip',
                tooltip: 'Once enabled, vouchers history can be shown to participants',
            },
            redeemVoucher: {
                ...this.baseProps,
                text: 'Vouchers Redemption',
                dataTestId:'vouchers-redemption-tooltip',
                tooltip: 'This functionality gives participants the ability to redeem vouchers for prizes',
            },
            queryWallet: {
                ...this.baseProps,
                text: 'Wallet',
                dataTestId:'wallet-tooltip',
                tooltip: 'Once enabled, participants will be able to see their wallets',
            },
            listPrizes: {
                ...this.baseProps,
                text: 'Enable Prize Catalog',
                dataTestId:'prize-catalog-tooltip',
                tooltip: 'Allows you to show the prize catalog',
            },
            captchaSecret: {
                ...this.baseProps,
                text: 'Web Integration',
                dataTestId:'web-integration-tooltip',
                tooltip: 'Allows you to add a captcha secret to the promotion',
            },
            promoTeaser: {
                ...this.baseProps,
                text: 'Promotion Teaser',
                dataTestId:'promotion-teaser-tooltip',
                tooltip: `Allows you to enable a promotion teaser before the start date to build consumers' awareness. This option will be automatically disabled if the current date is older than the promotion start date`,
            },
            minAge: {
                ...this.baseProps,
                text: 'Minimum Age Limit',
                dataTestId:'min-age-tooltip',
                tooltip: `If option is enabled, age range is between ${MIN_AGE} and ${MAX_AGE}`,
            },
        };
    },

    'Prize Draw (Lottery)'() {
        return {
            imageEntry: {
                ...this.baseProps,
                text: 'Image Promotion Entry',
                dataTestId:'image-promotion-entry-prize-draw',
                tooltip: 'The Image Promo Entry option will enable an image upload as a promotion entry',
            },
            captchaSecret: {
                ...this.baseProps,
                text: 'Add Captcha Secret',
                dataTestId:'add-captcha-secret-prize-draw',
                tooltip: 'Allows you to add a captcha secret to the promotion',
            },
            customAdditionalInfo: {
                ...this.baseProps,
                text: 'Additional Information',
                tooltip: 'In additionalInfo option, you can add custom key-value pairs which will be added as a parameter in the additionalInfo.',
            },
        };
    },

    'Instant Win'() {
        return {
            queryVouchers: {
                ...this.baseProps,
                text: 'Voucher History',
                dataTestId:'voucher-history-instant-win',
                tooltip: 'Allows you to show the history of claimed vouchers per participant',
            },
            listPrizes: {
                ...this.baseProps,
                text: 'Enable Prize Catalog',
                dataTestId:'enable-prize-catalog',
                tooltip: 'Allows you to show the prize catalog',
            },
            promoTeaser: {
                ...this.baseProps,
                text: 'Promotion Teaser',
                dataTestId:'promotion-teaser-instant-win',
                tooltip: `Allows you to enable a promotion teaser before the start date to build consumers' awareness. This option will be automatically disabled if the current date is older than the promotion start date`,
            },
            captchaSecret: {
                ...this.baseProps,
                text: 'Add Captcha Secret',
                dataTestId:'add-captcha-secret-instant-win',
                tooltip: 'Allows you to add a captcha secret to the promotion',
            },
            interruptedFlow: {
                ...this.baseProps,
                text: 'Enable Interrupted Flow',
                dataTestId:'enable-interrupted-flow-instant-win',
                tooltip:
                    `Once enabled, Reserved status will be used for vouchers that are distributed within this promotion.

                    The values defines how many vouchers can be accepted in a single request.
                    `,
            },
            customAdditionalInfo: {
                ...this.baseProps,
                text: 'Additional Information',
                dataTestId:'additional-information-instant-win',
                tooltip: 'In additionalInfo option, you can add custom key-value pairs which will be added as a parameter in the additionalInfo.',
            },
        };
    },
} as const;

export const componentConfigPropKeys = {
    minAge(promoFlow: string) {
        return {
            checkBox: JSON.stringify([
                'formState.minAgeEnabled',
                `flow.${promoFlow}.params.minAge`,
                'configurationParameters.additionalInformation.minAge',
                { key: `flow.${promoFlow}.checkerLambdas`, value: promoFlowCheckerLambdas.minAge },
            ]),
            textField: [
                `flow.${promoFlow}.params.minAge`,
                'configurationParameters.additionalInformation.minAge',
            ].toString(),
        };
    },
    promoTeaser() {
        return {
            checkBox: [
                'formState.promotionTeaserEnabled',
                'configurationParameters.additionalInformation.visibleFromDate',
            ].toString(),
            datePicker: ['configurationParameters.additionalInformation.visibleFromDate'].toString(),
        };
    },
    interruptedFlow() {
        return {
            checkBox: [
                'flow.instantWin.params.useStatusReserved',
                'flow.acceptReservedVoucher',
                'flow.rejectReservedVoucher',
                'flow.rejectWinningMoment',
            ].toString(),
            textField: ['flow.acceptReservedVoucher.params.maxParticipationIds'].toString(),
        };
    },
    captchaSecret() {
        return {
            checkBox: ['formState.captchaSecretEnabled', 'configurationParameters.captchaSecret'].toString(),
            textField: ['configurationParameters.captchaSecret'].toString(),
        };
    },
    listPrizes() {
        return {
            checkBox: ['flow.listPrizes'].toString(),
            radioGroup: ['flow.listPrizes.params.priorityOrder'].toString(),
        };
    },
    wvUrl: () => ['configurationParameters.additionalInformation.wv_url'].toString(),
    redeemPrize: () => ['flow.redeemPrize'].toString(),
    addTransaction: () => ['flow.addTransaction'].toString(),
    listTransactions: () => ['flow.listTransactions'].toString(),
    queryVouchers: () => ['flow.queryVouchers'].toString(),
    redeemVoucher: () => ['flow.redeemVoucher'].toString(),
    queryWallet: () => ['flow.queryWallet'].toString(),
    imageEntry: () => ['flow.promoEntry.params.imageEntry'].toString(),
    tags: () => ['configurationParameters.additionalInformation.tags'].toString(),
    identityProvider: () => ['configurationParameters.userIdType'].toString(),
    customAdditionalInfo() {
        return {
            checkBox: ['formState.additionalInfoEnabled', 'configurationParameters.additionalInformation'].toString(),
            textField: ['configurationParameters.additionalInformation'].toString(),
        };
    },
} as const;

export const pageErrorsMap: PageErrors = new Map();

export const identityProviders = [
    { value: 'cds', label: 'CDS' },
    { value: 'cid', label: 'CID' },
] as const;
