const MUItheme: { [key: string]: any } = {
    palette: {
        primary: {
            main: "#000",
        },
        secondary: {
            main: "#f2f2f2",
        },
    },
    typography: {
        fontFamily: "Roboto, sans-serif",
        h3: {
            fontSize: "2em",
            fontWeight: 600,
            color: "#43425D",
        },
        body1: {
            fontSize: "16px",
            fontWeight: "normal",
            color: "#43425D",
        },
        body2: {
            fontSize: "12px",
            fontWeight: "normal",
            color: "#43425D",
        },
        subheader: {
            fontSize: 24,
            fontWeight: "bold",
            margin: "30px 0px",
        },
        h4: {
            fontSize: "1.5em",
            fontWeight: 600,
        },
        h5: {
            fontFamily: "Roboto, sans-serif",
            fontSize: "24px",
            color: "#43425D",
        },
        caption: {
            fontSize: "10px",
            color: "#5F5F5F",
        },
        overline: {
            font: "normal normal medium Roboto",
            fontWeight: "bold",
            fontSize: "12px",
            opacity: 1,
            color: "#43425D",
            lineHeight: 'none',
            textTransform: 'none'
        },
        subtitle1: {
            fontSize: "0.875rem"
        }
    },
    components: {
        MuiAppBar: {
            styleOverrides: {
                colorPrimary: {
                    backgroundColor: "white",
                    color: "black",
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                iconSizeMedium: {
                    fontSize: "15px",
                },
            },
        },
        MuiFormControlLabel: {
            styleOverrides: {
                label: {
                    fontSize: "12px",
                },
            },
        },
        MuiMenuItem: {
            styleOverrides: {
                root: {
                    fontSize: "12px",
                },
            },
        },
        MuiCheckbox: {
            styleOverrides: {
                root: {
                    color: "white",
                    "&.Mui-checked": {
                        color: "#219376",
                    },
                },
            },
        },
    }
};

export default MUItheme;
