import { useState, SyntheticEvent, useEffect } from 'react';
import { Box, Tabs, Tab, Button, IconButton } from '@mui/material';
import { NavBarProps } from '../../types/componentTypes/navBar';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import './NavBar.css';

function NavBar({ tabLabels, components, navClass, tabsClass, customAction, customTabIndex, resetCustomIndex, enableTabRemove, deleteTabButtonCustomAction, enableDirectionSelectArrows, onTabChange }: NavBarProps) {
    const [currentTabIndex, setTabIndex] = useState(0);
    const [forceRerender, setForceRerender] = useState(false);

    useEffect(() => {
        if (customTabIndex !== undefined) {
            setTabIndex(customTabIndex);
            if (resetCustomIndex) resetCustomIndex();
        }
    }, [forceRerender, customTabIndex, resetCustomIndex, tabLabels, onTabChange])

    const handleChange = (event: SyntheticEvent, newValue: number) => {
        setTabIndex(newValue);
        if (onTabChange) {
            onTabChange(newValue);
        }
    };


    let navTabs = tabLabels.map((label, i) => {
        return <Tab onClick={(event) => { handleChange(event, i); if (customAction) { customAction(event, i) } }} key={i} label={label}/>
    });

    let navTabsWithDeleteButton = navTabs.map((individualTab, i) => {
        return [
            <Box className='langTabStyles'>
                {
                    [individualTab, (!(i === 0 || i === 1) ?
                        <Button
                            key={i + "button"}
                            onClick={() => {
                                if (deleteTabButtonCustomAction) {
                                    deleteTabButtonCustomAction(tabLabels[i]);
                                }
                                tabLabels.splice(i, 1);
                                (i === 1) ? setTabIndex(1) : setTabIndex(i - 1);
                                setForceRerender(forceRerender ? false : true);
                            }}
                            className='cancelTabsButton'>
                            x
                        </Button> :
                        null)]
                }
            </Box >]
    })

    return (
        <Box>
            {enableDirectionSelectArrows ?
                <Box className='navBarArrowTabs'>
                    <IconButton onClick={(event) => handleChange(event, (currentTabIndex - 1))} sx={{ height: '40px' }} disabled={currentTabIndex === 0 ? true : false} disableFocusRipple={true}>
                        <ArrowBackIosNewIcon >
                        </ArrowBackIosNewIcon>
                    </IconButton>
                    <Box className={navClass ? navClass : 'nav-main-box'}>
                        <Box className='prizeLangSelectClass'>
                            <Tabs TabIndicatorProps={{ className: tabsClass ? tabsClass : 'tab-indicators' }} value={currentTabIndex} >
                                {enableTabRemove ? navTabsWithDeleteButton : navTabs}
                            </Tabs>
                        </Box>
                    </Box>
                    <IconButton onClick={(event) => handleChange(event, (currentTabIndex + 1))} sx={{ height: '40px' }} disableFocusRipple={true} disabled={currentTabIndex === tabLabels.length - 1 ? true : false}>
                        <ArrowForwardIosIcon>
                        </ArrowForwardIosIcon>
                    </IconButton>
                </Box> :
                <Box className={navClass ? navClass : 'nav-main-box'}>
                    <Tabs TabIndicatorProps={{ className: tabsClass ? tabsClass : 'tab-indicators' }} value={currentTabIndex} >
                        {enableTabRemove ? navTabsWithDeleteButton : navTabs}
                    </Tabs>
                </Box>
            }
            <Box>
                {components[currentTabIndex]}
            </Box>
        </Box>
    );
}

export { NavBar };
