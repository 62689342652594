import { useFormikContext} from 'formik';
import { ExpandMore } from '@mui/icons-material';
import { Modal, Button, Typography, Box, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import './DeleteAdditionalResourcesLinkedToCurrencyModal.css';



const descriptionGenerator = (allocRulesLength: number, referralRulesLength: number) => {
    const referralRulesPart: string = referralRulesLength ? `${(referralRulesLength === 1) ? `1 Referral Rule` : `${referralRulesLength} Referral Rules`}` : ''
    const allocRulesPart: string = allocRulesLength ? `${(allocRulesLength === 1) ? `1 Allocation Rule` : `${allocRulesLength} Allocation Rules`}` : ''
    return `${referralRulesPart }${referralRulesPart && allocRulesPart && ' and '}${allocRulesPart} linked to this Currency`
}



function DeleteAdditionalResourcesLinkedToCurrency({submittedFromModal, additionalResourcesToBeDeleted ,modalOpen, handleClose, useFormik}) {
        const formik = useFormikContext()

        const funcMaster = () => {
            submittedFromModal(true);
            formik.handleSubmit()
            handleClose();
        }


    return (
        <Modal sx = {{overflow: 'scroll'}} open={modalOpen} onClose={() => handleClose()} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
            <Box className="modal_main">
                <Box className="enhancedBody">
                    <Typography id="modal-modal-title" sx={{ fontSize: '17x', fontWeight: 'bolder', textAlign: 'center' }}>
                        Removing this Currency from the Promotion will also remove or disable additional resources. Please review the connections to
                        each currency.
                    </Typography>
                </Box>
                <Box className={'neumorphicAccordionBox'}>
                { Object.keys(additionalResourcesToBeDeleted).map((currencyId, index) =>
                    <Accordion key={index} defaultExpanded>
                    <AccordionSummary expandIcon={<ExpandMore />}  aria-controls="panel1-content" id={`deleteLinkedResources-accordion-row-${index}`}>
                        <Typography sx={{fontWeight: 'bold'}}> {`${additionalResourcesToBeDeleted[currencyId]?.currencyName} (${currencyId})`} </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                        {descriptionGenerator(additionalResourcesToBeDeleted[currencyId]?.allocationRulesToBeDeleted?.length, additionalResourcesToBeDeleted[currencyId]?.referralRewardsKeysToBeDeleted?.length)}
                        </Typography>
                    </AccordionDetails>
                </Accordion>
                )}
                </Box>
                <Box sx={{marginBottom: '10px'}}>
                    <Button onClick={funcMaster} sx={{marginLeft: '10px'}} variant='contained' className="button">DELETE</Button>
                    <Button  variant='outlined' onClick={() => handleClose()} className="button">
                        CANCEL
                    </Button>
                </Box>
            </Box>
        </Modal>
    );
}

export { DeleteAdditionalResourcesLinkedToCurrency };
