import * as yup from 'yup';
import moment from "moment";

interface TestContextExtended {
    [key: string]: any;
}

const testStartEndDateRange = (value: any, compareWith: string | number, type: string, inUse: boolean): boolean => {

    if (inUse &&
        (
            (type === 'start' && moment(value).isBefore(moment(compareWith), 'day')) ||
            (type === 'end' && moment(value).isAfter(moment(compareWith), 'day'))
        )
    ) {
        return false;
    }

    return true;
}

const testPositiveNumber = (value: any, inUse: boolean): boolean => {
    if (inUse &&
        (!value
            || isNaN(parseInt(value))
            || typeof parseInt(value) !== 'number'
            || parseInt(value) < 0)
    ) {
        return false;
    }

    return true;
};

const alphaNumaricRegex = /^[a-zA-Z0-9]+$/

function assignPromotionValidationSchema() {
    return yup.object({
        tempParams: yup.object().shape({
            burnPincodes: yup.boolean(),
            pincodeOriginValitidy: yup.boolean(),
            flowOption: yup.string().required('Promotion mechanic has to be selected'),
            startDateTime: yup.date().required()
                .test('startDateTest', 'Promotion start date cannot be earlier than the campaign start date',
                    (value, testContext) => {
                        const { from } = testContext as yup.TestContext & TestContextExtended
                        let result = true;
                        if (moment(from[1].value.configurationParameters.configurationStartUtc).isAfter(moment(value), 'minute')) {
                            result = false
                        }
                        return result
                    })
                .test('startDateTest', 'Promotion start date cannot be in the past',
                    (value) => {
                        let result = true;
                        let date = new Date();
                        if (moment(value).isBefore(date, 'minute')) {
                            result = false
                        }
                        return result
                    }),
            endDateTime: yup.date().required()
                .test('endDateTest', 'Promotion end date cannot be later than the campaign end date',
                    (value, testContext) => {
                        const { from } = testContext as yup.TestContext & TestContextExtended
                        let result = true;
                        if (moment(from[1].value.configurationParameters.configurationEndUtc).isBefore(moment(value), 'minute')) {
                            result = false
                        }
                        return result
                    }),
            currencyNames: yup.array().required().test('c&g currency test', 'At least one currency must be selected',
                (value, testContext) => {
                    const { from } = testContext as yup.TestContext & TestContextExtended;
                    let result = true;
                    if (from[0].value.flowOption === "Collect & Get" && (value && value?.length < 1)) {
                        result = false;
                    }
                    return result
                })
        }),
        params: yup.object().shape({
            reduceAmount: yup.mixed()
                .test('reduceAmountTest', 'Currency has to be selected',
                    (value, testContext) => {
                        const { from } = testContext as yup.TestContext & TestContextExtended
                        let result = true;
                        value && value.forEach((element: { currencyId: string, amount: string }) => {
                            if (from[1].value.tempParams.useCurrency && element.currencyId === undefined) {
                                result = false
                            }
                        })
                        return result
                    })
                .test('reduceAmountTest', 'Cost must be a positive number',
                    (value, testContext) => {
                        const { from } = testContext as yup.TestContext & TestContextExtended
                        let result = true;
                        value && value.forEach((element: { currencyId: string, amount: string }) => {
                            if (from[1].value.tempParams.useCurrency && (!(/^\d+$/.test(element.amount)) || (Number(element.amount) <= 0))) {
                                result = false
                            }
                        })
                        return result
                    }),
        }),
        secrets: yup.object().shape({
            mixCodesParameters: yup.mixed()
                .test('mixcodesTest',
                    'If functionality is applied, mixcodes details are required',
                    (value, testContext) => {
                        const { from } = testContext as yup.TestContext & TestContextExtended
                        let result = true;
                        value && value.forEach((element: { programId: string, secret: string, uri: string }) => {
                            if ((element.programId === undefined || element.secret === undefined || element.uri === undefined) && from[1].value.tempParams.burnPincodes === true) {
                                result = false
                            }
                        });
                        return result
                    })
                .test('programIdTest',
                    'Program Id must contain alphanumeric characters with a length of 26',
                    (value, testContext) => {
                        const { from } = testContext as yup.TestContext & TestContextExtended
                        let result = true;
                        value && value.forEach((element: { programId: string, secret: string, uri: string }) => {
                            if (from[1].value.tempParams.burnPincodes === true && element.programId !== undefined && (!alphaNumaricRegex.test(element.programId) || element.programId.length !== 26)) {
                                result = false
                            }
                        });
                        return result
                    })
        }
        ),
        configurationParameters: yup.object({
            additionalInformation: yup.object({
                name: yup
                    .string()
                    .max(90, 'The maximum length of the campaign name is 90 characters')
                    .required('Promotion name cannot be empty'),
            }),
            country: yup
                .string()
                .required('Market has to be selected'),
            language: yup
                .string()
                .required('Language has to be selected'),
            captchaSecret: yup
                .string()
                .test('captchatest', 'If option is selected, value must be provided',
                    (value, context: yup.TestContext & TestContextExtended) => {
                        const { from } = context
                        if (from[1].value.tempParams.addCaptcha && (!value || value === '')) {
                            return false
                        }
                        return true
                    })
        }),
        participationLimits: yup.object().shape({
            participationLifetimeLimit: yup.mixed().test('participationLifetimeLimitTest',
                'This information is required if option is selected and should be a positive number',
                (value, context: yup.TestContext & TestContextExtended) => testPositiveNumber(value, context.from[1].value.tempParams.limits.lifetime)
            ),
            participationLimit: yup.mixed().test('participationLimitTest',
                'This information is required if option is selected and should be a positive number',
                (value, context: yup.TestContext & TestContextExtended) => testPositiveNumber(value, context.from[1].value.tempParams.limits.rolling)
            ),
            participationLimitTime: yup.mixed()
                .test('participationLimitTimeTest',
                    'This information is required if option is selected and should be a positive number',
                    (value, context: yup.TestContext & TestContextExtended) => testPositiveNumber(value, context.from[1].value.tempParams.limits.rolling)
                ),
            participationLimitCalendarDatesLimit: yup.mixed().test('participationLimitCalendarDatesLimitTest',
                'This information is required if option is selected and should be a positive number',
                (value, context: yup.TestContext & TestContextExtended) => testPositiveNumber(value, context.from[1].value.tempParams.limits.calendarDay)
            ),
            participationLimitCalendarDatesRange: yup.object({
                startDate: yup.mixed()
                    .test('participationLimitCalendarDatesRange-startDate',
                        "Selected dates can't be in the past or after the promotion end",
                        (value, context: yup.TestContext & TestContextExtended) => testStartEndDateRange(value, context.from[2].value.tempParams[`startDateTime`], 'start', context.from[2].value.tempParams.limits.calendarDay)
                    )
                    .test('participationLimitCalendarDatesRange-startDate-after-end',
                        "Selected date cannot be after end date",
                        (value, context: yup.TestContext & TestContextExtended) => testStartEndDateRange(value, context.from[0].value.endDate, 'end', context.from[2].value.tempParams.limits.calendarDay)

                    ),
                endDate: yup.mixed()
                    .test('participationLimitCalendarDatesRange-endDate',
                        "Selected dates can't be in the past or after the promotion end",
                        (value, context: yup.TestContext & TestContextExtended) => testStartEndDateRange(value, context.from[2].value.tempParams[`endDateTime`], 'end', context.from[2].value.tempParams.limits.calendarDay)
                    )
                    .test('participationLimitCalendarDatesRange-endDate-before-start',
                        "Selected date cannot be before start date",
                        (value, context: yup.TestContext & TestContextExtended) => testStartEndDateRange(value, context.from[0].value.startDate, 'start', context.from[2].value.tempParams.limits.calendarDay)
                    ),
            }),
            participationLimitStartEndDatesRange: yup.object({
                startDate: yup.mixed()
                    .test('participationLimitStartEndDatesRange-startDate',
                        "Selected dates can't be in the past or after the promotion end",
                        (value, context: yup.TestContext & TestContextExtended) => {
                            return testStartEndDateRange(value, context.from[2].value.tempParams[`startDateTime`], 'start', context.from[2].value.tempParams.limits.useRollingCustomDuration)
                        }
                    )
                    .test("participationLimitStartEndDatesRange-startDate-after-end",
                        "Selected date cannot be after end date",
                        (value, context: yup.TestContext & TestContextExtended) => testStartEndDateRange(value, context.from[0].value.endDate, 'end', context.from[2].value.tempParams.limits.useRollingCustomDuration)
                    ),
                endDate: yup.mixed()
                    .test('participationLimitStartEndDatesRange-endDate',
                        "Selected dates can't be in the past or after the promotion end",
                        (value, context: yup.TestContext & TestContextExtended) => testStartEndDateRange(value, context.from[2].value.tempParams[`endDateTime`], 'end', context.from[2].value.tempParams.limits.useRollingCustomDuration)
                    )
                    .test("participationLimitStartEndDatesRange-endDate-before-start",
                        "Selected date cannot be before start date",
                        (value, context: yup.TestContext & TestContextExtended) => testStartEndDateRange(value, context.from[0].value.startDate, 'start', context.from[2].value.tempParams.limits.useRollingCustomDuration)
                    ),
            }),
            minAge: yup.mixed()
                .test('minAgeTest',
                    'Minimum age must be a positive integer from 1 to 120 only',
                    (value, testContext) => {
                        const { from } = testContext as yup.TestContext & TestContextExtended;
                        const limit = from[1].value.tempParams.limits.minAge;

                        if (limit && (
                            !(/^\d+$/.test(value))
                            || parseInt(value) <= 0
                            || parseInt(value) > 120)
                        ) {
                            return false;
                        }

                        return true;
                    })
        }),
        allocationRules: yup.array().of(
            yup.object().shape({
                currencyName: yup.string()
                    .test('currencyNameTest', "Currency has to be selected",
                        (value, testContext) => {
                            const { from } = testContext as yup.TestContext & TestContextExtended;
                            let result = true;
                            if (value === undefined && from[1].value.tempParams.addRules === true) {
                                result = false
                            }
                            return result
                        }),
                amount: yup.mixed()
                    .test('amountTest', "Amount must be positive number",
                        (value, testContext) => {
                            const { from } = testContext as yup.TestContext & TestContextExtended;
                            let result = true;
                            if ((!Number.isInteger(parseInt(value)) || parseInt(value) < 0) && from[1].value.tempParams.addRules === true) {
                                result = false
                            }
                            return result
                        }),
            })
        ),
        referralRewards: yup.object().shape({
              referrer: yup.object()
                .default({})
                .shape({
                  currency: yup.string().test(
                    'Referrer box Currency test',
                    'Please select a Currency to be awarded to the referrer',
                    (value, testContext) => {
                      const fromTestCtx: any = testContext;
                      let currentState = fromTestCtx?.from[2]?.value;
                      let result = true;
                      if (currentState?.referrerBox && !currentState?.referralRewards?.referrer?.currency) {
                        result = false;
                      }
                      return result;
                    }
                  ),
                  amount: yup.number()
                    .test('Referrer Box Currency amount test', 'Please type in an amount between 1 and 9999 to be awarded to the referrer', (value, testContext) => {
                      const fromTestCtx: any = testContext;
                      let currentState = fromTestCtx?.from[2]?.value;
                      let result = true;
                      if (currentState?.referrerBox && (currentState?.referralRewards?.referrer?.amount === 0  || currentState?.referralRewards?.referrer?.amount > 9999 || !currentState?.referralRewards?.referrer?.amount)) {
                        result = false;
                      }
                      return result;
                    })
                }),
            referee: yup.object()
                .default({})
                .shape({
                  currency: yup.string().test(
                    'Referrer box Currency test',
                    'Please select a Currency to be awarded to the referee',
                    (value, testContext) => {
                      const fromTestCtx: any = testContext;
                      let currentState = fromTestCtx?.from[2]?.value;
                      let result = true;
                      if (currentState?.refereeBox && !currentState?.referralRewards?.referee?.currency) {
                        result = false;
                      }
                      return result;
                    }
                  ),
                  amount: yup.number()
                    .test('Referrer Box Currency amount test', 'Please type in an amount between 1 and 9999 to be awarded to the referee', (value, testContext) => {
                      const fromTestCtx: any = testContext;
                      let currentState = fromTestCtx?.from[2]?.value;
                      let result = true;
                      if (currentState?.refereeBox && (currentState?.referralRewards?.referee?.amount === 0  || currentState?.referralRewards?.referee?.amount > 9999 || !currentState?.referralRewards?.referee?.amount)) {
                        result = false;
                      }
                      return result;
                    })
                })
            }),
        maxParticipationIds: yup.number()
            .nullable(true)
            .max(100)
            .min(1)
            .label('Maximum vouchers')
    });
}

export { assignPromotionValidationSchema }
