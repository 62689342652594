import { Box, Typography } from '@mui/material';
import { FormikContextType, getIn } from 'formik';
import { useEffect, useState } from 'react';
import { CheckboxComponent } from '../../inputComponents/CheckboxComponent';
import TooltipText from '../../inputComponents/TooltipText';
import { RadioButton } from '../../inputComponents/RadioButton';
import { AdditionalDetailsMechanicSpecifics } from './AdditionalDetailsMechanicSpecifics';
import './AdditionalPromoDetails.css';
import CaptchaCheckerComponent from './CaptchaChecker.component';
import { ICreatePromotionState } from '../../../types/componentTypes/assignPromo';

interface AdditionalPromoDetailsProps {
    formik: FormikContextType<ICreatePromotionState>;
}

function AdditionalPromoDetails({ formik }: AdditionalPromoDetailsProps) {
    const [mechanic, setMechanic] = useState(formik.values.tempParams.flowOption);

    useEffect(() => {
        setMechanic(formik.values.tempParams.flowOption);
    }, [formik.values.tempParams.flowOption]);

    const flowsMap = {
        addTransaction: { flowLambdas: ['transactionLambda'] },
        autoRedeemCnG: { flowLambdas: ['autoRedeemPrizeLambda'] },
        listPrizes: {
            flowLambdas: ['prizeQueryLambda'],
            params: {
                priorityOrder: formik.values.priorityOrder,
                additionalInformation: true,
            },
        },
        queryVouchers: { flowLambdas: ['digitalCodesQueryByUserLambda'] },
        queryWallet: { flowLambdas: ['walletLambda'] },
        listTransactions: {flowLambdas: ["listTransactionsByUserLambda"]},
        redeemPrize: {
            checkerLambdas: ['prizeCheckerLambda', 'currencyCheckerLambda'],
            flowLambdas: ['prizeRedeemLambda'],
        },
        redeemVoucher: { flowLambdas: ['digitalCodesRedeemLambda'] },
    };

    const addFlow = (e: any) => {
        const { checked, name } = e.target;
        const flow = name.split('.')[1];
        if (checked) {
            formik.setFieldValue('additionalDetails', {
                ...formik.values.additionalDetails,
                [flow]: flowsMap[flow as keyof typeof flowsMap],
            });
        } else {
            const additionalDetails = getIn(formik.values, 'additionalDetails');
            delete additionalDetails[flow];
            formik.setFieldValue('additionalDetails', additionalDetails);
        }
    };

    const handlePriorityParam = () => {
        formik.setFieldValue(
            'additionalDetails.listPrizes.params.priorityOrder',
            formik.values.priorityOrder === 'ASC' ? 'DESC' : 'ASC'
        );
    };

    const handleImagePromotionEntry = (
        e: React.ChangeEvent<HTMLInputElement>
    ) => {
        const { checked } = e.target;
        if (checked) {
            formik.setFieldValue('params.imageEntry', true);
        } else {
            formik.setFieldValue('params.imageEntry', false);
        }
    };

    return (
        <Box className='promo-details'>
            <Typography variant='h4'>Additional Details</Typography>
            <Typography className='typography-style' variant='body1'>
                Select functionalities required for this promotion
            </Typography>
            <Box className='promo-options'>
                {mechanic !== 'Prize Draw (Lottery)' && (
                    <>
                        <CheckboxComponent
                            customBoxClass='voucher-checkbox'
                            fieldName={'tempParams.listPrizes'}
                            label={
                                <TooltipText
                                    dataTestId='prize-catalog-tooltip'
                                    styleName='details-checkbox-tooltip'
                                    text='Prize Catalog (Optional)'
                                    textVariant='body1'
                                    tooltip={
                                        'Once enabled, prize catalog can be shown to participants'
                                    }
                                />
                            }
                            customAction={addFlow}
                        />
                        {formik.values.tempParams.listPrizes && (
                            <Box>
                                <Typography variant='body1'>Prize List Priority</Typography>
                                <RadioButton
                                    formik={formik}
                                    customAction={handlePriorityParam}
                                    values={[
                                        { value: 'ASC', label: 'Ascending' },
                                        { value: 'DESC', label: 'Descending' },
                                    ]}
                                    radioGroupName={'priorityOrder'}
                                />
                            </Box>
                        )}
                        <CheckboxComponent
                            customBoxClass='voucher-checkbox'
                            fieldName={'tempParams.queryVouchers'}
                            label={
                                <TooltipText
                                    dataTestId='vouchers-history-tooltip'
                                    styleName='details-checkbox-tooltip'
                                    text='Vouchers History (Optional)'
                                    textVariant='body1'
                                    tooltip={
                                        'Once enabled, vouchers history can be shown to participants'
                                    }
                                />
                            }
                            customAction={addFlow}
                        />
                    </>
                )}

                <CaptchaCheckerComponent formik={formik} />

                {mechanic === 'Prize Draw (Lottery)' && (
                    //! Temp fix just to fix the bug in release-123 and will be refactored in BUG 710914
                    <CheckboxComponent
                        fieldName={'params.imageEntry'}
                        label={
                            <TooltipText
                                styleName='image-promotion-checkbox-tooltip'
                                text='Image Promotion Entry'
                                textVariant='body1'
                                tooltip={
                                    'The Image Promo Entry option will enable an image upload as a promotion entry'
                                }
                            />
                        }
                        customBoxClass='imagePromoEntryCheckbox'
                        customAction={handleImagePromotionEntry}
                    />
                )}

                {mechanic !== 'Prize Draw (Lottery)' && (
                    <AdditionalDetailsMechanicSpecifics
                        formik={formik}
                        mechanic={mechanic}
                        customActions={[addFlow]}
                    />
                )}
            </Box>
        </Box>
    );
}

export { AdditionalPromoDetails };
