import { SyntheticEvent } from 'react';
import { FormHelperText, Box } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import {Checkbox, Link, Typography} from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { getIn } from 'formik';
import './MultipleSelectOptions.css';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import TextFieldWrapper from '../../common/TextFieldWrapper/TextFieldWrapper';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
interface MultipleSelectOptionsProps {
    formik: any,
    title?:string,
    subTitle?:string,
    listOfOptions:any,
    formikFieldName:string,
    inputLabel:string,
    enableAdditional?:boolean,
    additionalLabel?:string,
    handleOpen?: any,
    modalComponent?:JSX.Element,
    customAction?: any,
    boxStyleCustomClass?: string,
    preSelectedValue?: any,
    formControlClass?: string,
    disableDelete?: boolean
}

function MultipleSelectOptions({formik, title, subTitle, listOfOptions, formikFieldName, inputLabel, enableAdditional=false, additionalLabel='', handleOpen, modalComponent, customAction, preSelectedValue, boxStyleCustomClass, formControlClass, disableDelete = false}: MultipleSelectOptionsProps) :any {
    let options: Array<string> = Array.from(new Set([...listOfOptions ,...getIn(formik.values, formikFieldName)])) as Array<string>;
    const isDeleteDisabled =
        disableDelete && getIn(formik.values, formikFieldName)?.length === 1;
    if (options.length > 1 ) {
        options = options.filter(option => option !== '')
    }
    return (
        <Box className = {boxStyleCustomClass ? boxStyleCustomClass : ''}>
            {title &&  <Typography variant="h4">{title} </Typography>}
            {subTitle && <Typography variant="body1" classes={{root: "subTitle"}}>{subTitle}</Typography>}
            <Box>
                <FormControl className={formControlClass || "formControl"}>
                    <Autocomplete
                        multiple
                        id={`${formikFieldName}-autocomplete`}
                        options={options}
                        getOptionLabel={(option) => option.toString()}
                        value={preSelectedValue ? preSelectedValue : getIn(formik.values,formikFieldName)|| ['']}
                        classes={{root: "autoCompleteRoot", tag: "tag", endAdornment: "endAdornment"}}
                        popupIcon={ <ArrowDropDownIcon fontSize="large" classes={{root: "dropDownIcon"}} /> }
                        onChange={(e, value) => {if (isDeleteDisabled && value.length < 1) return; formik.setFieldValue(formikFieldName, value); if(customAction) {customAction(value)}}}
                        renderOption={(props, option, { selected }) => (
                            <li {...props}>
                            <Checkbox
                                icon={icon}
                                checkedIcon={checkedIcon}
                                style={{ marginRight: 8 }}
                                checked={ selected }
                            />
                            {option}
                            </li>
                        )}
                        renderInput={(params) => (
                            <TextFieldWrapper {...params} label={inputLabel} error={getIn(formik.touched, formikFieldName) && Boolean(getIn(formik.errors, formikFieldName))} />
                        )}
                        />
                    {enableAdditional && <><Link classes={{root: "additionalOptionLink"}} onClick={(e: SyntheticEvent) => handleOpen(e)}>{additionalLabel}</Link>
                        {modalComponent}
                    </>}
                    <FormHelperText error={true}>{getIn(formik.touched, formikFieldName) && getIn(formik.errors, formikFieldName)}</FormHelperText>
                </FormControl>
            </Box>
        </Box>
    )
}

export { MultipleSelectOptions }
