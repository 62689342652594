import { additionalInfo, ConfigType, PrizeDrawConfig } from '../../../../types/configuration';
import { MIN_AGE, PARTICIPATION_IDS_DEFAULT } from '../constants/AdditionalDetailsVars';
import { AdditionalDetailsState, CustomInfo, Mechanic } from '../types/AdditionalDetailsTypes';
import { CachedConfigKeys, ComponentCache } from './ComponentCache';
import { TagManager } from './TagManager';

/**
 * The `CacheManager` class is responsible for managing the configuration cache and form state cache for the Additional Details section of a promotion.
 *
 * It provides methods to:
 * - Get the updated configuration object
 * - Retrieve the cached configuration of a specified type
 * - Get the cached configuration value for a specified key
 * - Update the configuration value for a specified form state key
 * - Remove a configuration value from the updated configuration
 * - Get the cached form state
 * - Set the form state cache with a provided `AdditionalDetailsState` object
 * - Generate the initial form state for the Additional Details section
 *
 * The `CacheManager` is a singleton class, and its instance can be obtained using the `getInstance()` method.
 */
export class CacheManager extends ComponentCache {
    private tagManager: TagManager;

    private static instance: CacheManager;
    private constructor(config: ConfigType | PrizeDrawConfig) {
        super();
        this.setupConfigCache(config);
        this.tagManager = new TagManager();
    }

    // Instance methods
    public static getInstance(config: ConfigType | PrizeDrawConfig): CacheManager {
        return (CacheManager.instance = new CacheManager(config));
    }

    public static deleteInstance() {
        if (!CacheManager.instance) return;

        CacheManager.instance = null;
    }

    // Config Methods

    /**
     * Returns the updated configuration object.
     * @returns {ConfigType | PrizeDrawConfig} The updated configuration object.
     */
    getUpdatedConfig(): ConfigType | PrizeDrawConfig {
        return this.getConfig('updated-config');
    }

    /**
     * Retrieves the cached configuration of the specified type.
     *
     * @param configType - The type of configuration to retrieve.
     * @returns The cached configuration of the specified type, or the prize draw configuration if the specified type is not found.
     */
    getConfig(configType: CachedConfigKeys): ConfigType | PrizeDrawConfig {
        return this.getCachedConfig(configType);
    }

    /**
     * Retrieves the cached configuration value for the specified key.
     * @param key - The configuration key to retrieve the value for.
     * @returns The cached configuration value for the specified key.
     */
    getCachedConfigValue(key: string) {
        return this.getInitConfigValue(key);
    }

    /**
     * Updates the configuration value for the specified form state key.
     *
     * @param formStateKey - The key of the form state to update.
     * @param value - The new value to set, excluding 'null' and 'undefined'.
     */
    updateConfigValue<T>(formStateKey: string, value: Omit<T, 'null' | 'undefined'>) {
        this.setUpdatedConfigValue(formStateKey, value);
    }

    /**
     * Removes a configuration value from the updated configuration.
     * @param key - The key of the configuration value to remove.
     */
    removeConfigValue(key: string, parentKeyValue: string = 'params') {
        this.removeUpdatedConfigValue(key, parentKeyValue);
    }

    // FormState Methods
    /**
     * Returns the cached form state, which is the result of calling `getInitFormState()`.
     * @returns The cached form state.
     */
    getCachedFormState() {
        return this.getInitFormState();
    }

    /**
     * Sets the form state cache with the provided `formState` object.
     *
     * @param formState - The `AdditionalDetailsState` object to set as the form state cache.
     */
    setFormStateCache(formState: AdditionalDetailsState) {
        this.setInitFormState(formState);
    }

    getCustomAdditionalInfo(additionalInfo): CustomInfo[] {
        const predefinedItems = ['name', 'visibleFromDate', 'tags', 'wv_url', 'minAge', 'description', 'shortDescription', 'imgUrl', 'totalCurrencyAccumulated'];
        return Object.entries(additionalInfo).reduce((arr, curr) => {
            const [key, value] = curr;
            if(!predefinedItems.includes(key) && value && typeof key === 'string') arr.push({ key, value });
            return arr;
        }, []);
    }

    getPredefinedAdditionalInfo(additionalInfo: additionalInfo) {
        const {
            name,
            visibleFromDate,
            tags,
            wv_url,
            minAge,
            description,
            shortDescription,
            imgUrl,
            totalCurrencyAccumulated,
        } = additionalInfo;

        return {
            name,
            visibleFromDate,
            tags,
            wv_url,
            minAge,
            description,
            shortDescription,
            imgUrl,
            totalCurrencyAccumulated,
        }
    }

    /**
     * Generates the initial form state for the Additional Details section of a promotion.
     *
     * @param params - An object containing the configuration, promotion flow, and promotion mechanic.
     * @param params.config - The configuration for the promotion, either a `ConfigType` or `PrizeDrawConfig`.
     * @param params.promoFlow - The name of the promotion flow.
     * @param params.promoMechanic - The promotion mechanic.
     * @returns The initial state for the Additional Details form.
     */
    getInitialFormState(params: {
        config: ConfigType | PrizeDrawConfig;
        promoFlow: string;
        promoMechanic: Mechanic;
    }): AdditionalDetailsState {
        const { config, promoFlow, promoMechanic: mechanic } = params;

        const { flow, configurationParameters } = config;
        const { userDefinedTags, defaultTags } = this.tagManager.getTags(config, promoFlow, mechanic);
        const { configurationEndUtc, configurationStartUtc } = configurationParameters;

        const visibleFromDate = configurationParameters?.additionalInformation?.visibleFromDate;
        const configHasMinAge =
            flow[promoFlow]?.params?.minAge || configurationParameters?.additionalInformation?.minAge;

        const promoFlowCheckers = new Set(config.flow[promoFlow]?.checkerLambdas || []);

        const updatedState: AdditionalDetailsState = {
            // *functionality
            addTransaction: !!flow['addTransaction'],
            listTransactions: !!flow['listTransactions'],
            queryWallet: !!flow['queryWallet'],
            redeemPrize: !!flow['redeemPrize'],
            redeemVoucher: !!flow['redeemVoucher'],
            imageEntry: !!flow.promoEntry?.params?.imageEntry,
            listPrizes: !!flow?.listPrizes,
            queryVouchers: !!flow?.queryVouchers,
            useStatusReserved: !!flow?.instantWin?.params?.useStatusReserved,

            // *values
            maxParticipationIds: flow?.acceptReservedVoucher?.params?.maxParticipationIds || PARTICIPATION_IDS_DEFAULT,
            promoFlow: promoFlow,
            defaultTags: Array.from(defaultTags),
            tags: Array.from(userDefinedTags),
            configEndUtc: configurationEndUtc,
            configStartUtc: configurationStartUtc,
            userIdType: configurationParameters?.userIdType || 'cds',
            priorityOrder: flow['listPrizes']?.params?.priorityOrder || 'ASC',
            wv_url: configurationParameters?.additionalInformation?.wv_url || '',
            captchaSecret: configurationParameters.captchaSecret || '',
            visibleFromDate: visibleFromDate || 0,
            minAge: configHasMinAge || MIN_AGE,

            // *UI checkboxes
            minAgeEnabled: !!configHasMinAge,
            captchaSecretEnabled: !!configurationParameters?.captchaSecret,
            promotionTeaserEnabled: !!visibleFromDate,
            additionalInfoEnabled: !!this.getCustomAdditionalInfo(configurationParameters?.additionalInformation).length,
            customAdditionalInfo: this.getCustomAdditionalInfo(configurationParameters?.additionalInformation),
            predefinedAdditionalInfo: this.getPredefinedAdditionalInfo(configurationParameters?.additionalInformation),

            // *checkers
            checkerLambdas: promoFlowCheckers,
        };

        this.setInitFormState(updatedState);
        return updatedState;
    }
}
