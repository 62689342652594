import { useState, Dispatch, SetStateAction, useEffect } from 'react';
import { Box, Typography, Link } from '@mui/material';
import { Form, Formik } from 'formik';
import { useParams } from 'react-router-dom';
import { ensureOrderOfFlowLambdas } from '../../AssignPromotion/AssignPromotionPage';
import { CurrenciesForm } from './CurrenciesForm';
import { saveConfig } from '../../../utils/s3FileUtils';
import { notificationType } from '../../../types/notifications';
import { boxTypeMapNames } from '../FriendReferral/constants/referralRewards-constants'
import { IUpdatedConfig, updateCurrencies, fetchRelevantAllocRules, updateAllocRules, SubmitExitValues } from './services/api-calls';
import { currenciesValidationSchema } from './CurrenciesValidationSchema';
import useGetCurrencyTabParams from './hooks/useGetCurrencyTabParams';
import { DeleteAdditionalResourcesLinkedToCurrency } from './../../../components/modals/DeleteAdditionalResourcesLinkedToCurrencyModal'
import { allocationRules } from '../../../types/componentTypes/allocationRules'
import { deleteAdditionalResourceType } from '../../../types/componentTypes/deleteAdditionalResourcesTypes'
import { CurrencySubmitValues, EditCurrenciesState } from '../../../types/componentTypes/editPromotion';
import { helpfulLinks } from './constants/currency-constants';

import GenericSpinner from '../../../common/Spinners/GenericSpinner';

import './Currencies.css';

interface CurrenciesProps {
    mechanic: string;
    setNotificationState: Dispatch<SetStateAction<notificationType>>;
    handleTabChange: Dispatch<SetStateAction<boolean>>;
}

const processCheckerArrays = ({ initialCheckers = [], updatedCheckers = [] }: {initialCheckers?: string[], updatedCheckers?: string[]}) => {
    if(!updatedCheckers.length) return initialCheckers;

    if(!initialCheckers.length) return updatedCheckers;

    return [...initialCheckers, ...updatedCheckers]
}

const Currencies = ({ setNotificationState, handleTabChange, mechanic }: CurrenciesProps) => {
    const urlParams = useParams();
    const fileName = `${urlParams.promotionId}/conf.txt`;

    const [initialState, setInitialState] = useState<EditCurrenciesState>();
    const [updatedConfigInState, setUpdatedConfigInState]= useState<IUpdatedConfig>()
    const [shouldDeleteAllocRules, setShouldDeleteAllocRules] = useState<boolean>(false);
    const [showLoadingSpinner, setShowLoadingSpinner] = useState<boolean>(false);
    const [submittedFromModal, setSubmittedFromModal ] = useState<boolean>(false);
    const [allocRulesForConfig, setAllocRulesForConfig] = useState<allocationRules[]>()
    const [deleteAdditionalResourcesModalOpen, setDeleteAdditionalResourcesModalOpen] = useState<boolean>(false)
    const [additionalResourcesToBeDeleted, setAdditionalResourceToBeDeleted] = useState<deleteAdditionalResourceType>({})
    const [currentConfigCurrencies, setCurrentConfigCurrencies] = useState<string[]>([]);
    const [promoFlow, setPromoFlow] = useState<string>('');

    useEffect(() => {
        if (mechanic === 'Collect & Get' || mechanic === 'Auto Collect & Get') {
            fetchRelevantAllocRules(urlParams.promotionId).then(fetchedRules => {
                setAllocRulesForConfig(fetchedRules)
            })
        }
    },[mechanic, urlParams?.promotionId])

    const { isLoading, refetchCurrencies } = useGetCurrencyTabParams({
        fileName,
        mechanic,
        setPromoFlow,
        setInitialState,
        setNotificationState,
        setCurrentConfigCurrencies,
    });


    const handleSubmitValues = (values: CurrencySubmitValues): SubmitExitValues  => {
        const { params, tempParams, flowLambdas, ...rest } = values;
        let updatedValues = {
            ...rest,
            flow: {
                ...rest.flow,
                [promoFlow]: {
                    ...rest?.flow[promoFlow],
                    ...(rest.flow[promoFlow]?.params && {
                        params: {
                            ...rest.flow[promoFlow].params,
                        },
                    }),
                    ...(rest?.flow[promoFlow]?.checkerLambdas && {
                        checkerLambdas: rest?.flow[promoFlow]?.checkerLambdas
                    })
                },
            },
        };
        if (promoFlow === 'instantWin' || promoFlow === 'promoEntry') {
            if (!tempParams.useCurrency) {
                if (updatedValues.flow[promoFlow]?.params?.reduceAmount) {
                    delete updatedValues.flow[promoFlow]?.params?.reduceAmount;
                    delete updatedValues.configurationParameters.currencies;
                }
                updatedValues.flow[promoFlow].checkerLambdas = updatedValues.flow[promoFlow]?.checkerLambdas?.filter(checkerLambda => checkerLambda !== 'currencyCheckerLambda')
                const currencyReducerExists = updatedValues.flow[promoFlow].flowLambdas.find(
                    (item: string) => item === 'currencyReducer'
                );
                if (currencyReducerExists) {
                    updatedValues.flow[promoFlow].flowLambdas = updatedValues.flow[promoFlow].flowLambdas.filter(
                        (lambda: String) => lambda !== 'currencyReducer'
                    );
                }
                if (updatedValues.configurationParameters.currencies) {
                    delete updatedValues.configurationParameters.currencies;
                }
                if (updatedValues.flow[promoFlow]?.params?.reduceAmount) {
                    delete updatedValues.flow[promoFlow].params.reduceAmount;
                }
            } else {
                updatedValues.flow[promoFlow].flowLambdas = ensureOrderOfFlowLambdas(flowLambdas);
                updatedValues.flow[promoFlow].checkerLambdas = processCheckerArrays({
                    initialCheckers: updatedValues.flow[promoFlow]?.checkerLambdas,
                    updatedCheckers: rest?.checkerLambdas
                })
                delete updatedValues.checkerLambdas;
                updatedValues.flow[promoFlow].params.reduceAmount = params.reduceAmount;
            }
        }

        if (promoFlow === 'redeemPincodeForCurrencies' || promoFlow === 'autoRedeemCnG') {
            if (tempParams.totalCurrencyAccumulated) {
                updatedValues.configurationParameters.additionalInformation.totalCurrencyAccumulated = true;
            } else if (
                !tempParams.totalCurrencyAccumulated &&
                updatedValues.configurationParameters.additionalInformation.totalCurrencyAccumulated
            ) {
                delete updatedValues.configurationParameters.additionalInformation.totalCurrencyAccumulated;
            }

            if (tempParams.currencyValidity) {
                const validityValues = rest.configurationParameters.currencies.reduce((acc: any, currency: string) => {
                    acc[currency] = tempParams.validityDays;
                    return acc;
                }, {});
                updatedValues.configurationParameters.validity = validityValues;
                updatedValues.flow.queryExpirationWalletByUser = { flowLambdas: ["queryExpirationWalletLambda"]};
            } else if (!tempParams.currencyValidity && updatedValues.configurationParameters.validity) {
                delete updatedValues.configurationParameters.validity;
                delete updatedValues.flow.queryExpirationWalletByUser;
            }
        }
        let removeAdditionalResourcesTemp: deleteAdditionalResourceType = {}
        let exitToModal: boolean = false;
        if ((initialState.configurationParameters?.currencies?.reduce((acc, currVal) => acc + currVal) !== updatedValues.configurationParameters?.currencies?.reduce((acc, currVal) => acc + currVal))) {
            if (updatedValues?.referralRewards) {
                Object.keys(boxTypeMapNames).forEach(key => {
                    if ((!updatedValues.configurationParameters?.currencies.includes(updatedValues?.referralRewards[key]?.currency)) && updatedValues?.referralRewards[key]) {
                        Object.assign(removeAdditionalResourcesTemp, {[updatedValues?.referralRewards[key]?.currency]: {referralRewardsKeysToBeDeleted: [key]}})
                        removeAdditionalResourcesTemp[updatedValues?.referralRewards[key]?.currency]?.currencyName || (removeAdditionalResourcesTemp[updatedValues?.referralRewards[key]?.currency].currencyName = tempParams.availableCurrencies.find(currObject => currObject.currency_id === updatedValues?.referralRewards[key]?.currency)?.name)
                        delete updatedValues?.referralRewards[key]
                    }
                })
                Object.keys(updatedValues?.referralRewards).length || delete updatedValues?.referralRewards
            }

            if ((mechanic === 'Collect & Get' || mechanic === 'Auto Collect & Get') && allocRulesForConfig.length) {
                allocRulesForConfig.forEach(allocObject => {
                    if (!updatedValues.configurationParameters?.currencies.includes(allocObject.currency_id)) {
                        !shouldDeleteAllocRules && setShouldDeleteAllocRules(true);
                        removeAdditionalResourcesTemp[allocObject.currency_id] || Object.assign(removeAdditionalResourcesTemp, {[allocObject.currency_id]: {allocationRulesToBeDeleted: [allocObject]}})
                        removeAdditionalResourcesTemp[allocObject.currency_id]?.currencyName || (removeAdditionalResourcesTemp[allocObject.currency_id].currencyName = tempParams.availableCurrencies.find(currObject => currObject.currency_id === allocObject.currency_id)?.name)
                        removeAdditionalResourcesTemp[allocObject.currency_id]?.allocationRulesToBeDeleted ?
                        (!(removeAdditionalResourcesTemp[allocObject.currency_id]?.allocationRulesToBeDeleted?.find(allocObjectAlreadyInArray => allocObjectAlreadyInArray.currency_id === allocObject.currency_id)) &&
                        removeAdditionalResourcesTemp[allocObject.currency_id]?.allocationRulesToBeDeleted.push(allocObject)) :
                        removeAdditionalResourcesTemp[allocObject.currency_id].allocationRulesToBeDeleted = [allocObject]
                    }
                })
            }

            if (Object.keys(removeAdditionalResourcesTemp).length) {
                exitToModal = true // this is returned from the function and not set in state due to it being required to determine the order of operations in the submit whereas state updates are batches and occurr once per event
                setAdditionalResourceToBeDeleted(removeAdditionalResourcesTemp)
                setUpdatedConfigInState(updatedValues);
                submittedFromModal || setDeleteAdditionalResourcesModalOpen(true)
            }
        }
        return {
            updatedConfig: updatedValues,
            exitToModal: exitToModal
        }

    };

    return (
        <>
            <GenericSpinner showSpinner={isLoading || showLoadingSpinner} />

            <Box className='currencies-main-box'>
                <Box className='currencies-tap-wrapper'>
                    <Typography variant='h3'>Currencies</Typography>
                </Box>

                {!isLoading && initialState?.params && (
                    <>
                        <Formik
                            initialValues={initialState}
                            enableReinitialize
                            validationSchema={currenciesValidationSchema}
                            onSubmit={async (values: any, { resetForm }) => {
                                if (submittedFromModal) {
                                    try {
                                        if (shouldDeleteAllocRules) {
                                            await updateAllocRules(Object.keys(additionalResourcesToBeDeleted).map(key => {
                                                return additionalResourcesToBeDeleted[key].allocationRulesToBeDeleted
                                            }).flat())
                                        }
                                    } catch (e) {
                                        console.error('Deleting the allocation rules failed with', e)
                                    }
                                }
                                    try {
                                        if (promoFlow === 'redeemPincodeForCurrencies' && values.tempParams.currencyNames.length < 1) {
                                            setNotificationState({
                                                open: true,
                                                title: 'Currency is mandatory',
                                                content: 'C&G promotion cannot be saved without currency.',
                                                level: 'error',
                                            });
                                            resetForm()
                                        } else {
                                        const { updatedConfig, exitToModal } = submittedFromModal ? {updatedConfig: updatedConfigInState, exitToModal: false} : handleSubmitValues(values)
                                        if (!exitToModal) {
                                            let inputUpdatedConfig = submittedFromModal ? updatedConfigInState : updatedConfig
                                            await saveConfig({ urlParams, submitData: inputUpdatedConfig, setNotificationState });
                                            const availableCurrencies = await refetchCurrencies();

                                            await updateCurrencies({
                                                availableCurrencies,
                                                inputUpdatedConfig,
                                                currentConfigCurrencies,
                                                previousCurrencies: currentConfigCurrencies,
                                                setNotificationState,
                                                setCurrentConfigCurrencies,
                                            });
                                            setNotificationState({
                                                open: true,
                                                title: 'Promotion updated successfully',
                                                content: 'Promotion Changes were saved!',
                                                level: 'success',
                                            });
                                        }
                                    }
                                    } catch (e) {
                                        console.error(e);
                                    } finally {
                                        setShowLoadingSpinner(false);
                                    }
                            }}>
                            <Form>
                                <Box>
                                {deleteAdditionalResourcesModalOpen  &&
                                <DeleteAdditionalResourcesLinkedToCurrency submittedFromModal={setSubmittedFromModal} useFormik={true} additionalResourcesToBeDeleted = {additionalResourcesToBeDeleted} modalOpen={deleteAdditionalResourcesModalOpen} handleClose={setDeleteAdditionalResourcesModalOpen}/>
                                }
                                <CurrenciesForm handleTabChange={handleTabChange} />
                                </Box>

                            </Form>
                        </Formik>
                        <Typography variant='h3' className='links-title'>
                            Helpful Links
                        </Typography>
                        {promoFlow && (
                            <Box className='helpfulLinks'>
                                {helpfulLinks[promoFlow].map((helpfulLink, i) => (
                                    <Link key={i} target='_blank' href={helpfulLink.link} rel='noopener noreferrer'>
                                        {helpfulLink.text}
                                    </Link>
                                ))}
                            </Box>
                        )}
                    </>
                )}
            </Box>
        </>
    );
};

export { Currencies };
