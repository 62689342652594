import { useState, useEffect } from 'react'
import { ConfigType } from '../../../../types/configuration';
import { referralFormPropType } from '../constants/referralRewards-constants'


export const referralFormInitialValues: referralFormPropType = {
    referrerBox: false,
    refereeBox: false,
    referralRewards: {
        referrer: {
            amount: 0,
            currency: '',
            sendEmail: false,
            limit: 1
        },
        referee: {
            amount: 0,
            currency: '',
            sendEmail: false,
            limit: 1
        },

    }
};

const useReferralInitialState = (config: ConfigType) => {
    const [initialState, setInitialState] = useState<referralFormPropType>(referralFormInitialValues);

    const inferStateFromConfig = (inputConfig : ConfigType) => {
        if (!inputConfig?.referralRewards) {
            setInitialState(referralFormInitialValues);
            return;
        }
        const actualState = {
            referrerBox: false,
            refereeBox: false,
            referralRewards: {...inputConfig.referralRewards},
        }

        Object.entries(inputConfig?.referralRewards).forEach(([key, value]) => {
            const boxField = `${key}Box`;
            actualState[boxField] = true;
        });

        setInitialState(actualState)
    }

    useEffect(() => {
        inferStateFromConfig(config)
    },[config])

    return { initialState }

}

export default useReferralInitialState;
