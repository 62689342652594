import { Box, Button, Typography, Icon, Tooltip } from '@mui/material';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { useState } from 'react';
import { useFormikContext } from 'formik';
import { SelectOption } from './../../../components/selectComponents/SelectOption'
import { InputComponent } from '../../../components/inputComponents/InputComponent';
import { CheckboxComponent } from '../../../components/inputComponents/CheckboxComponent';
import { referralFormInitialValues } from './hooks/useReferralInitialState'
import { boxTypeMapNames, friendReferraPropsType } from './constants/referralRewards-constants'
import TooltipText from '../../../components/inputComponents/TooltipText';
import { ToolTipPlacement } from '../../../types/componentTypes/tooltip';
import './FriendReferral.css'

const boxTypes: string[] = ['referrer', 'referee']

const ReferralInputBox = ({formik, boxToggle, inputFieldsDisabled, currencyOptionList, boxType, isEdit}) => {
    const limitTitleStyle = !inputFieldsDisabled ? 'limitTitleDisabled' : 'limitTitleActive'
    let customLabelMap =  currencyOptionList.map(currObject => {
            return {
                        [currObject.currency_id]: currObject.currency_id,
                        customLabel: `${currObject.name} (${currObject.currency_id})`
                    }
        })
    return (
        <Box className='outerBoxStyle'>
        <CheckboxComponent
        customBoxClass= {isEdit ? 'referralEnableBoxStyle' : 'referralEnableBoxStyleAtWizard'}
        testingId={`${boxType}Box`}
        fieldName={`${boxType}Box`}
        label={`${boxTypeMapNames[boxType]} Rewards`}
        customAction={boxToggle}
        />
            <Box className={inputFieldsDisabled ? 'innerBoxStyleActive' : 'innerBoxStyleDisabled'}>
            <div className='toolTipDiv'>
            <CheckboxComponent
                customBoxClass='emailSendingSelectorBox'
                disabledCondition={!inputFieldsDisabled}
                testingId={`${boxType}-EmailSendingCheckbox`}
                fieldName={`referralRewards.${boxType}.sendEmail`}
                label={`Enable ${boxTypeMapNames[boxType]} Notifications`}
            />
            <Tooltip
                arrow
                sx={{ marginTop: '40px', marginRight: '25px' }}
                classes={{ tooltip: 'tooltip_text' }}
                data-testId={`${boxType}-send-email`}
                title={'The notifications are sent by the AWS SES (Simple Email Service)'}
                placement={'bottom'}>
                <Icon
                    className='icon-style'
                    fontSize={'medium'}>
                    <HelpOutlineIcon />
                </Icon>
            </Tooltip>
            </div>
            <Typography className={`referralAllocationLabel${inputFieldsDisabled ? 'Active' : 'Disabled'}`}>
                Add Currency Allocation
            </Typography>
                <SelectOption
                    title = {'Currency Name and ID'}
                    titleVariant = {'body2'}
                    dataTestId={`${boxType}-currIdSelect`}
                    customLabelValueMap={customLabelMap}
                    selectClass={'currencyIdSelectStyle'}
                    disabled={!inputFieldsDisabled}
                    formikValue= {`referralRewards.${boxType}.currency`}
                    optionsList={customLabelMap}
                    />
                <InputComponent
                titleVariant = {'body2'}
                title={'Amount'}
                inputClassName={'currencyAmountInputStyle'}
                disabled={!inputFieldsDisabled}
                formik={formik}
                formikLabel={`referralRewards.${boxType}.amount` || ''}
                inputValue={formik.values?.referralRewards?.[boxType]?.amount || ''}
                />
                <TooltipText
                    text='Limit'
                    tooltip={`Limit the number of times the ${boxType} can accumulate a reward`}
                    textVariant='body1'
                    iconSize='medium'
                    styleName='limitTooltipGrid'
                    placement={ToolTipPlacement.Right}
                    textClassName={limitTitleStyle}
                />
                <InputComponent
                    titleVariant = {'body2'}
                    title={''}
                    inputClassName={'limitInputStyle'}
                    disabled={!inputFieldsDisabled}
                    formik={formik}
                    formikLabel={`referralRewards.${boxType}.limit` || ''}
                    inputValue={formik.values?.referralRewards?.[boxType]?.limit || ''}
                    dataTestId={`${boxType}-limit`}
                    type='number'
                />
            </Box>
        </Box>
    )
}

const FriendReferralForm = ({config, nonFormikCurrList, isEdit }: friendReferraPropsType) => {
    const [referrerFieldsEnabled, setReferrerFieldsEnabled ] = useState<boolean>(isEdit ? !!config?.referralRewards?.referrer : false);
    const [refereeFieldsEnabled, setRefereeFieldsEnabled ] = useState<boolean>(isEdit ? !!config?.referralRewards?.referee : false);
    const formik = useFormikContext()

    const clearValuesOnBoxToggle = (boxToClear: string) => {
        formik.setFieldValue((boxToClear === 'referrerBox') ? 'referralRewards.referrer' : 'referralRewards.referee', undefined )
    }

    const boxToggle = (inputEvent: { target: { checked: boolean | ((prevState: boolean) => boolean); name: string; }; }, inputFormik?) => {
        const boxToggleSetMap = {
            referrerBox: setReferrerFieldsEnabled,
            refereeBox: setRefereeFieldsEnabled
        }
        if (!inputEvent?.target?.checked) {
            clearValuesOnBoxToggle(inputEvent.target?.name)
        } else {
            inputEvent.target?.name === 'referrerBox' ?
            formik.setFieldValue('referralRewards.referrer', referralFormInitialValues.referralRewards.referrer) :
            formik.setFieldValue('referralRewards.referee', referralFormInitialValues.referralRewards.referee)
        }
        return boxToggleSetMap[inputEvent.target?.name](inputEvent?.target?.checked);
    }

    return (
        <>
        {!isEdit &&
           <Typography className='typography-style' variant='body1'>
              Specify the referral rewards for this promotion
          </Typography>
        }
        <div className={isEdit ? 'refDivStyle' : 'refDivStyleAtWizard'}>
        {boxTypes.map((type: string, index: number) =>
                        <ReferralInputBox
                        isEdit={isEdit}
                        key={index}
                        formik={formik}
                        boxToggle={boxToggle}
                        inputFieldsDisabled={(type==='referrer')? referrerFieldsEnabled : refereeFieldsEnabled}
                        currencyOptionList={nonFormikCurrList}
                        boxType={type}
                        />

                    )}
            </div>
                {isEdit &&
                <Box sx={{paddingLeft: '230px', marginTop: '30px', justifyContent: 'center', display: 'flex'}}>
                <Button variant='outlined' disabled={false} onClick={() =>  formik.resetForm({ values: formik.initialValues })}>
                    DISCARD
                </Button>
                <Button sx={{width: '100px', marginLeft: '15px'}} variant='contained' color='primary' type='submit'>
                    SAVE
                </Button>
                </Box>
                }
        </>
    )
}

export { FriendReferralForm, ReferralInputBox }
