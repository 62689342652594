import { ConfigType } from '../../../../types/configuration';
import {
    CurrencyTableItem
} from '../../../../types/componentTypes/CurrencyTypes';

export interface friendReferralType {
    config?: ConfigType

}

export const boxTypeMapNames = {
    referrer: 'Referrer',
    referee: 'Referee'
}

export interface friendReferraPropsType {
    config?: ConfigType,
    nonFormikCurrList?: CurrencyTableItem[],
    isEdit?: boolean
}

export interface RefProperties {
    amount: number,
    currency: string,
    sendEmail: boolean,
    limit: number
}

export interface RefProperities {
    amount: number,
    currency: string,
    sendEmail: boolean,
    limit: number
}

export interface referralFormPropType {
    referrerBox: boolean,
    refereeBox: boolean,
    referralRewards: {
        referrer: RefProperties,
        referee: RefProperties
    }
}
