import { SyntheticEvent } from 'react';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Snackbar from '@mui/material/Snackbar';
import { SetNotificationStateType, notificationType } from '../../types/notifications';
import { Box } from '@mui/material';

interface NotificationProps {
    notificationState: notificationType;
    setNotificationState: SetNotificationStateType;
    messageVisibilityTime?: number;
}

function Notification({ notificationState, setNotificationState, messageVisibilityTime = 6000 }: NotificationProps) {
    const handleClose = (_event: SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setNotificationState({ ...notificationState, open: false });
    };

    return (
        <Snackbar
            open={notificationState.open}
            autoHideDuration={messageVisibilityTime}
            onClose={handleClose}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
            <Alert
                variant='outlined'
                sx={{ background: 'white', border: '0px', boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px', maxWidth: '350px' }}
                severity={notificationState.level}>
                <AlertTitle>{notificationState.title}</AlertTitle>
                <Box sx={{fontSize: '14px', wordWrap: 'break-word'}}>{notificationState.content}</Box>
            </Alert>
        </Snackbar>
    );
}

export { Notification };
