import React, {useState, useEffect, useRef} from 'react';
import { Box, FormHelperText, Stack, Container, Typography } from '@mui/material';
import { DesktopDateRangePicker } from '@mui/x-date-pickers-pro';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterMoment } from '@mui/x-date-pickers-pro/AdapterMoment';
import moment from 'moment';
import { useField } from 'formik';
import TextFieldWrapper from '../../common/TextFieldWrapper/TextFieldWrapper';
import type { DateRange } from '@mui/x-date-pickers-pro/DateRangePicker';

interface DateRangePickerWrapperProps {
    description: string,
    formikDateRange?: string;
    datesClass?: string;
    formikStartDate: string;
    formikEndDate:string;
    isEdit?: string;
    title?: string;
}

const DateRangePickerWrapper = ({
    description,
    formikDateRange = 'dateRangeField',
    datesClass,
    formikStartDate,
    formikEndDate,
    isEdit,
    title
}: DateRangePickerWrapperProps) => {
    const [startDateField, , startDateFieldHelpers] = useField(formikStartDate)
    const [ , , endDateFieldHelpers] = useField(formikEndDate);
    const [dateRangeField, dateRangeFieldMeta, dateRangeFieldHelpers] = useField(formikDateRange);
    const [minDate, setMinDate] = useState(moment().utc());

    const calledOnce = useRef(false);

    useEffect(() => {
        if (!calledOnce.current && startDateField.value !== '') {
            const initialDate = moment(startDateField.value).utc(true);
            initialDate ? setMinDate(initialDate) : setMinDate(minDate);
            calledOnce.current = true;
        }
    }, [
        minDate,
        setMinDate,
        startDateField.value,
        isEdit,
    ]);

    const preventKeyboardInput = (e: any) => {
        e.preventDefault();
    };
    const handleDateRangeChange = (range: DateRange<moment.Moment>) => {
        dateRangeFieldHelpers.setValue(range);
        if (range[0] && range[0].isValid() && range[1] && range[1].isValid()) {
            dateRangeFieldHelpers.setValue([
                moment(range[0]).toDate().getTime(),
                moment(range[1]).endOf('day').toDate().getTime(),
            ]);
            startDateFieldHelpers.setValue(moment(range[0]).toDate().getTime());
            endDateFieldHelpers.setValue(moment(range[1]).endOf('day').toDate().getTime());
        }
    };

    return (
        <Container disableGutters>
            <Typography variant={'h3'} gutterBottom>
                {title}
            </Typography>
            <Typography variant={'body1'} sx={{ marginBottom: 2 }}>
                {description}
            </Typography>
        <LocalizationProvider
            dateAdapter={AdapterMoment}
            adapterLocale="en-US"
            localeText={{
                start: 'Start Date',
                end: 'End Date',
            }}>
            <Stack spacing={3} className={datesClass}>
                <DesktopDateRangePicker
                    value={dateRangeField.value}
                    minDate={minDate}
                    onChange={(value) => {
                        dateRangeFieldHelpers.setValue(value);
                        handleDateRangeChange(value);
                    }}
                    renderInput={(startProps, endProps) => (
                        <React.Fragment>
                            <TextFieldWrapper onKeyDown={preventKeyboardInput} className="input-text" {...startProps} />
                            <Box sx={{ mx: 2 }}> </Box>
                            <TextFieldWrapper onKeyDown={preventKeyboardInput} className="input-text" {...endProps} />
                        </React.Fragment>
                    )}
                />
                <FormHelperText error={true}>{dateRangeFieldMeta.error}</FormHelperText>
            </Stack>
        </LocalizationProvider>
        </Container>
    );
};

export  {DateRangePickerWrapper};
