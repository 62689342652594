import {
    Box, Button, Checkbox, TableBody, Table, TableCell, TableContainer, TableHead, TablePagination,
    TableRow, TableSortLabel, Typography, Paper, IconButton, Grid, Link, Skeleton
} from "@mui/material";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { Fragment, useState, useEffect } from 'react';
import { SortIcon } from '../VouchersTab/SortIcon';
import CircleIcon from '@mui/icons-material/Circle';
import { API } from 'aws-amplify';
import moment from 'moment-timezone';
import { timezones } from "../../../constants/timezones";
import { DateTimePicker } from "../../../components/winningMomentsComponent/DateTimePicker";
import { getPrizesForConfig, getWinningMoments } from '../../../graphql/queries';
import { updatePrizeCatalogueTable } from '../../../graphql/mutations';
import { Notification } from '../../../common/Notification/Notification';
import { UploadWinningMomentsModal } from '../../../components/modals/UploadWinningMomentsModal';
import { ConfigType, configurationParameters } from "../../../types/configuration";
import { defaultNotifState } from "../../../constants/currency-constants";
import { headers, helpfulLinks } from "./constants/winningMoments-constants";

import '../Prizes/Prizes.css';
import './WinningMoments.css';
import '../CommonStyles.css';

const uniqid = require('uniqid');


interface props {
    config: ConfigType,
    showGeneratorScreen?: any
}

interface prizeName {
    [key: string]: string
}
interface prizeType {
    name: string,
    configuration_id: string,
    start_date: number | string | null,
    end_date: number | string | null,
    prize_id: string,
    prize_activity_timezone?: string
}
function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

type Order = 'asc' | 'desc';

function getComparator<Key extends keyof any>(
    order: Order,
    orderBy: Key,
): (
    a: { [key in Key]: number | string },
    b: { [key in Key]: number | string },
) => number {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

const WinningMoments = ({ config, showGeneratorScreen }: props) => {

    const [order, setOrder] = useState<Order>('asc');
    const [checked, setChecked] = useState<readonly string[]>([]);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [initialPrizes, setInitialPrizes] = useState<Array<prizeType>>([{ name: '{}', start_date: null, end_date: null, prize_id: '', configuration_id: '' }]);
    const [prizes, setPrizes] = useState<Array<prizeType>>([{ name: '{}', start_date: null, end_date: null, prize_id: '', configuration_id: '' }]);
    const [wmUploadedStatus, setWmUploadedStatus] = useState<Array<any>>([]);
    const [page, setPage] = useState(0);
    const [notificationState, setNotificationState] = useState(defaultNotifState);
    const [loading, setLoading] = useState(true);
    const [changesApplied, setChangesApplied] = useState(false);
    const [changedPrizeIds, setChangedPrizeIds] = useState<Array<string | null>>([]);
    const [open, setOpen] = useState(false);


    useEffect(() => {
        const retrievePrizesData = async () => {
            const queryResult: any = await API.graphql({ query: getPrizesForConfig, variables: { configuration_id: config.configurationId } });
            const vouchersInfo = queryResult.data.getPrizesForConfig.items;
            return vouchersInfo;
        }

        const retrieveWinningMomentsPerPrize = async (configurationId: string, prizeId: string) => {
            const queryResult: any = await API.graphql({ query: getWinningMoments, variables: { configurationId: configurationId, prizeId: prizeId } });
            const winningMomentsInfo = queryResult.data.getWinningMoments.items;
            return winningMomentsInfo;
        }
        retrievePrizesData()
            .then(data => {
                setPrizes(data);
                setInitialPrizes(data);
                data.forEach((prize: any) => {
                    retrieveWinningMomentsPerPrize(config.configurationId, prize.prize_id)
                        .then(data => {
                            if (data.length === 1) {
                                setWmUploadedStatus(wmUploadedStatus => [...wmUploadedStatus, prize.prize_id]);
                            }
                        })
                        .catch(e => {
                            console.error('Error while retrieving winning moments status info', e)
                        })
                })
                setLoading(false)
                setChangesApplied(false)
            })
            .catch(e => {
                console.error('Error while retrieving vouchers info', e)
            })
    }, [config])

    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - prizes.length) : 0;

    const isSelected = (prize_id: string) => checked.indexOf(prize_id) !== -1;

    const handleChangePage = (
        event: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number,
    ) => {
        setPage(newPage);
    };
    const checkIfDatesAvailable = (prize: prizeType, configParams?: configurationParameters) => {
        if (!(prize.start_date || prize.end_date) && !(configParams?.configurationStartUtc || configParams?.configurationEndUtc)) {
            return false
        }
        return true;
    }

    const generateMomentsButtonHandler = (prizesInput: Array<prizeType>, generateForAllPrizes?: boolean, configurationParams?: configurationParameters) => {
        const passedPrizes = [];
        let canGenerate = true;
        for (let i = 0; i < prizesInput.length; i++) {
            if (generateForAllPrizes) {
                const datesAvailable = checkIfDatesAvailable(prizesInput[i], configurationParams);
                if(!datesAvailable) {
                    canGenerate = false;
                }

                passedPrizes.push({
                    prizeId: prizesInput[i].prize_id,
                    startDate: prizesInput[i].start_date,
                    endDate: prizesInput[i].end_date
                });
            } else if (!(checked.length === 0)) {
                if (checked.includes(prizesInput[i].prize_id)) {

                    const datesAvailable = checkIfDatesAvailable(prizesInput[i], configurationParams);
                    if(!datesAvailable) {
                        canGenerate = false;
                    }
                    passedPrizes.push({
                        prizeId: prizesInput[i].prize_id,
                        startDate: prizesInput[i].start_date,
                        endDate: prizesInput[i].end_date
                    });
                }
            }
        }

        canGenerate
            ? showGeneratorScreen({ showScreen: true, prizeParams: passedPrizes })
            : setNotificationState({
                open: true,
                title: "Error",
                content: "Prize(s) and promotion should have start and end date!",
                level: "error"
            })
    }
    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.checked) {
            const newSelecteds = prizes.map((n) => n.prize_id);
            setChecked(newSelecteds);
            return;
        }
        setChecked([]);
    };

    const handleClick = (event: React.MouseEvent<unknown>, prize_id: string) => {
        const selectedIndex = checked.indexOf(prize_id);
        let newSelected: readonly string[] = [];
        if (selectedIndex === -1) {
            newSelected = newSelected.concat(checked, prize_id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(checked.slice(1));
        } else if (selectedIndex === checked.length - 1) {
            newSelected = newSelected.concat(checked.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                checked.slice(0, selectedIndex),
                checked.slice(selectedIndex + 1),
            );
        }
        setChecked(newSelected);
    };

    const handleCopy = (prize: any) => {
        navigator.clipboard.writeText(prize.prize_id);
        setNotificationState({
            open: true,
            title: 'Prize Id Copied!',
            content: "",
            level: "success"
        })
    }

    const transformUTCtoTimezone = (prizeDate: number, timezoneString: string) => {
        let date;
        if (prizeDate) {
            const utcDate = moment.utc(prizeDate).format();
            const timezone = Object.keys(timezones).find(key => timezones[key] === timezoneString) || 'Europe/London';
            date = moment.tz(utcDate, timezone).format();
        } else {
            date = moment().format('YYYY-MM-DD HH:mm')
        }

        return date
    }

    const handleDateTimeChange = (params: { date: string, prize: prizeType, index: number, dateIndex: 'start_date' | 'end_date' }) => {
        const { date, prize, index, dateIndex } = params;
        const newPrizes = [...prizes].slice().sort(getComparator(order, 'name')).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
        let timezoneValue;
        if (!prize.prize_activity_timezone) {
            timezoneValue = moment.tz.guess();
            prize.prize_activity_timezone = timezoneValue;
        } else {
            timezoneValue = Object.keys(timezones).find(key => key === prize.prize_activity_timezone) || 'Europe/London';
        }

        prize[dateIndex] = moment.tz(date, timezoneValue).utc().valueOf();
        newPrizes[index] = { ...prize };
        setPrizes(newPrizes);
        setChangesApplied(true);
        setChangedPrizeIds(Array.from(new Set([...changedPrizeIds, prize.prize_id])))
    }


    const handleDiscardChanges = () => {
        setPrizes(initialPrizes);
        setChangesApplied(false);
        setChangedPrizeIds([]);
    }

    const handleSaveChanges = async () => {
        const saveUpdatedPrize = async (prize: prizeType) => {
            try {
                await API.graphql({
                    query: updatePrizeCatalogueTable, variables: {
                        input: {
                            configuration_id: prize.configuration_id,
                            prize_id: prize.prize_id,
                            start_date: prize.start_date,
                            end_date: prize.end_date
                        }
                    }
                });
            } catch (e) {
                console.error(`Prize update for ${prize.prize_id} failed with: `, e);
            }
        }

        const promises = changedPrizeIds.map((prizeId) => {
            return new Promise((resolve, reject) => {
                try {
                    const prizeToUpdate = prizes.find((item: prizeType) => item.prize_id === prizeId);
                    if (prizeToUpdate) {
                        saveUpdatedPrize(prizeToUpdate);
                        resolve(prizeToUpdate.prize_id);
                    }
                } catch (err) {
                    reject(err)
                }
            })
        })
        await Promise.all(promises)

        setNotificationState({
            open: true,
            title: 'Prize updated successfully!',
            content: `Prizes ${changedPrizeIds.join(',')} successfully updated`,
            level: "success"
        })
        setChangesApplied(false);
        setChangedPrizeIds([]);
    }


    return (
        <>
            <Notification notificationState={notificationState} setNotificationState={setNotificationState} />
            <UploadWinningMomentsModal modalOpenState={open} handleClose={setOpen} configId={config.configurationId} />
            <Box className='prizesWrapper'>
                <Box className='winningMomentsHeader'>
                    <Typography variant='h3'>Winning Moments</Typography>
                    <Button onClick={() => generateMomentsButtonHandler(prizes, true, config.configurationParameters)} disabled={loading || !prizes.length} variant="contained" color="primary" type="submit" className="generateMoments">Generate for all prizes</Button>
                    <Button variant="outlined" color="primary" onClick={() => setOpen(true)}>Upload</Button>
                    {checked.length > 0 && <Button onClick={() => generateMomentsButtonHandler(prizes, false, config.configurationParameters)} variant="outlined" color="primary" className='generateForSelected'>Generate For Selected Prize(s)</Button>}
                    {checked.length > 0 && <Typography variant="body1" className='selectedItemsN'>{`Selected: ${checked.length} item(s)`}</Typography>}
                </Box>
                <TableContainer component={Paper} className='vouchersContainer'>
                    <Table className='vouchersTable'>
                        <TableHead>
                            <TableRow className='voucherHeader'>
                                <TableCell padding="checkbox">
                                    <Checkbox
                                        indeterminate={checked.length > 0 && checked.length < prizes.length}
                                        checked={prizes.length > 0 && checked.length === prizes.length}
                                        onChange={handleSelectAllClick}
                                        sx={{ color: '#4e4f55' }}
                                    />
                                </TableCell>
                                {headers.map((header, i) =>
                                    <Fragment key={i}>
                                        {header === 'Prize Name'
                                            ? <TableCell key={i}>
                                                <TableSortLabel
                                                    active={true}
                                                    direction={order}
                                                    onClick={() => setOrder(order === 'asc' ? 'desc' : 'asc')}
                                                    IconComponent={() => <SortIcon order={order} />}
                                                >
                                                    <Typography variant='body2'>{header}</Typography>
                                                </TableSortLabel>
                                            </TableCell>
                                            : <TableCell key={i} align='center'><Typography variant='body2'>{header}</Typography></TableCell>}
                                    </Fragment>
                                )}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {!loading && prizes && prizes.slice().sort(getComparator(order, 'name')).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((prize: any, i: number) => {
                                const prizeName = Object.values(JSON.parse(prize.name) as prizeName)[0]
                                const isItemSelected = isSelected(prize.prize_id);
                                const prizeStartDate = transformUTCtoTimezone(prize.start_date, prize.prize_activity_timezone);
                                const prizeEndDate = transformUTCtoTimezone(prize.end_date, prize.prize_activity_timezone);
                                return <TableRow
                                    className='voucherRow'
                                    key={prize.prize_id}
                                    data-testid={`prizeRow-${prize.prize_id}`}
                                >
                                    <TableCell align='left'>
                                        <Checkbox
                                            onClick={(event) => handleClick(event, prize.prize_id)}
                                            checked={isItemSelected}
                                            sx={{ color: '#4e4f55' }}
                                        />
                                    </TableCell>
                                    <TableCell align='left'>
                                        <Grid container>
                                            <Grid item xs={8}>
                                                <Typography variant='body1'>{prizeName || 'No name available'}</Typography>
                                            </Grid>
                                            <Grid item xs={4}>
                                                <CircleIcon className={(wmUploadedStatus.includes(prize.prize_id)) ? 'circleIconActive' : 'circleIconInactive'} />
                                            </Grid>
                                        </Grid>
                                        <Typography variant='body2' >
                                            Prize ID: <span> {prize.prize_id}
                                                <IconButton onClick={() => handleCopy(prize)}>
                                                    <ContentCopyIcon className="copyPrizeId" />
                                                </IconButton>
                                            </span>
                                        </Typography>
                                    </TableCell>
                                    <TableCell align="center">
                                        <DateTimePicker earliestDate={prizeStartDate} handleChange={handleDateTimeChange} customParams={{ prize: prize, index: i, dateIndex: 'start_date' }} latestDate={prizeEndDate} valueToDisplay={'start'}/>
                                    </TableCell>
                                    <TableCell align="center">
                                        <DateTimePicker earliestDate={prizeStartDate} handleChange={handleDateTimeChange} customParams={{ prize: prize, index: i, dateIndex: 'end_date' }} latestDate={prizeEndDate} valueToDisplay={'end'}/>
                                    </TableCell>
                                </TableRow>
                            })}
                            {loading && Array(5).fill(1).map((el, i) =>
                                <TableRow key={i} >
                                    {Array(4).fill(1).map((el, i) => <TableCell key={i}><Skeleton /><Skeleton width="60%" /></TableCell>)}
                                </TableRow>)}

                            {emptyRows > 0 && (
                                <TableRow key={uniqid()} style={{ height: 50 * emptyRows }}>
                                    <TableCell colSpan={3} />
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[10, 15, 20, 25, { label: 'All', value: -1 }]}
                    component="div"
                    count={prizes.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    className='tablePagination'
                />
                {changesApplied &&
                    <Box className='changesButtonsBox'>
                        <Button variant='outlined' className='discardChangesButton' onClick={handleDiscardChanges}>DISCARD</Button>
                        <Button variant="contained" onClick={handleSaveChanges}>SAVE</Button>

                    </Box>}
                <Typography variant="h3">Helpful Links</Typography>
                <Box className='helpfulLinks'>
                    {helpfulLinks.map((helpfulLink, i) =>
                        <Link key={i} target='_blank' href={helpfulLink.link}>{helpfulLink.text}</Link>
                    )}
                </Box>
            </Box>
        </>
    )
}

export { WinningMoments }
