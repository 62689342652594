import { useState, useEffect } from 'react';
import { API } from 'aws-amplify';
import { getAllocationRulesForConfig } from '../../../graphql/queries';
import { allocationRulesEditProps } from '../../../types/componentTypes/allocationRules';
import { getCurrencies } from './../../../pages/Currencies/services/api-calls'
import {
    CurrencyTableItem
} from '../../../types/componentTypes/CurrencyTypes';
import { allocationRules } from '../../../types/componentTypes/allocationRules';
import EditIcon from '@mui/icons-material/Edit';
import ClearIcon from '@mui/icons-material/Clear';
import {
    Box,
    Button,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    Typography,
    Paper,
    Skeleton,
    Link,
} from '@mui/material';
import '../CommonStyles.css';
import { DeleteAllocationRule } from '../../../components/modals/DeleteAllocationRuleModal';
import { collectAndGetRef, currenciesRef } from '../../../constants/helpful-link';
const uniqid = require('uniqid');


const headers = ['Currency Name and ID', 'Amount', 'Program ID', "Lot ID/Campaign ID", "Validity", "Actions"];

const AllocationRules = ({ config, setShowAllocationRulePage }: allocationRulesEditProps) => {
    const [rules, setRules] = useState<Array<allocationRules>>([])
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [availableCurrencies, setAvailableCurrencies] = useState<CurrencyTableItem[]>()
    const [page, setPage] = useState(0);
    const [loading, setLoading] = useState(true);
    const [confirmDelete, setConfirmDelete] = useState(false);
    const [selectedRule, setSelectedRule] = useState<allocationRules>();

    useEffect(() => {
        const retrieveAllocationRules = async () => {
            const queryResult: any = await API.graphql({ query: getAllocationRulesForConfig, variables: { configuration_id: config.configurationId } });
            const rulesData = queryResult.data.getAllocationRulesForConfig.items;
            return rulesData;
        }

        const fetchAndFilterCurrencies =  (countryToDisplay: string) => {
            try {
                getCurrencies().then(fetchedCurrencies => {
                    setAvailableCurrencies((fetchedCurrencies as CurrencyTableItem[]).filter(currency => currency.country === countryToDisplay))
                })
            } catch (e) {
                console.log('Failed to fetch market relevant currencies with', e);
            }

        }
        fetchAndFilterCurrencies(config?.configurationParameters?.country)

        retrieveAllocationRules()
            .then(data => {
                const rulesData = data.filter((rule: allocationRules) => Boolean(rule.rule_active) === true)
                setRules(rulesData);
                setLoading(false)
            })
            .catch(e => {
                console.error('Error while retrieving rules info', e)
            })


    }, [config, selectedRule, availableCurrencies])

    const handleChangePage = (
        event: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number,
    ) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rules.length) : 0;

    const closeModal = () => {
        setSelectedRule(undefined);
        setConfirmDelete(false);
    }

    return (
        <>
            <Box>
                <DeleteAllocationRule modalOpenState={confirmDelete} rule={selectedRule} handleClose={closeModal} />
                <Box className='tabHeader'>
                    <Typography variant='h3'>Allocation Rules</Typography>
                    <Button variant="contained" color="primary" className='firstHeaderButton' onClick={() => { setShowAllocationRulePage({ showScreen: true }) }}>+ Add Allocation Rule</Button>
                </Box>
                <TableContainer component={Paper} className='vouchersContainer'>
                    <Table className='vouchersTable'>
                        <TableHead>
                            <TableRow className='voucherHeader'>
                                {headers.map((header, i) =>
                                    <TableCell key={i} align='center'><Typography variant='body2'>{header}</Typography></TableCell>
                                )}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {!loading && rules && rules.slice().slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((rule: allocationRules, i: number) => (
                                <TableRow key={uniqid()}>
                                    <TableCell align='center'>{`${availableCurrencies?.find(currObject => currObject.currency_id === rule.currency_id)?.name} (${rule.currency_id})`}</TableCell>
                                    <TableCell align='center'>{rule.amount}</TableCell>
                                    <TableCell align='center'>{rule.program_id}</TableCell>
                                    <TableCell align='center'>{rule.lot_id}</TableCell>
                                    <TableCell align='center'>{rule.validity}</TableCell>
                                    <TableCell align='center'>
                                        <IconButton onClick={() => { setShowAllocationRulePage({ showScreen: true, state: rule }) }}>
                                            <EditIcon />
                                        </IconButton>
                                        <ClearIcon onClick={() => { setConfirmDelete(true); setSelectedRule(rule) }} />
                                    </TableCell>
                                </TableRow>
                            )
                            )}
                            {loading && Array(7).fill(1).map((el, i) =>
                                <TableRow key={i} >
                                    {Array(6).fill(1).map((el, i) => <TableCell key={i}><Skeleton /><Skeleton width="60%" /></TableCell>)}
                                </TableRow>)}
                            {emptyRows > 0 && (
                                <TableRow key={uniqid()} style={{ height: 50 * emptyRows }}>
                                    <TableCell colSpan={6} />
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[10, 15, 20, 25, { label: 'All', value: -1 }]}
                    component="div"
                    count={rules.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    className='tablePagination'
                />
                <Typography variant='h3' className='links-title'>
                Helpful Links
            </Typography>
            <Box className='helpfulLinks'>
                <Link target='_blank' href={currenciesRef.ALLOCATION_RULES} rel='noopener noreferrer'>
                    Allocation Rules
                </Link>
                <Link target='_blank' href={collectAndGetRef.ADD_CG} rel='noopener noreferrer'>
                    How to add a Collect and Get promotion (Wiki and Video)
                </Link>
            </Box>
            </Box>
        </>

    )
}

export { AllocationRules }
