import { Modal, Typography, Box, IconButton, Button, Grid } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { Notification } from '../../common/Notification/Notification';
import { TooltipText } from '../inputComponents/TooltipText';
import { useState } from 'react';
import { defaultNotifState } from '../../constants/currency-constants';
import './Modal.css'
import { ToolTipPlacement } from '../../types/componentTypes/tooltip';
import { AllocateDates } from '../selectComponents/AllocateDates';
import { Formik, getIn } from 'formik';
import { API, graphqlOperation } from 'aws-amplify';
import { createPrizeCatalogueTable } from '../../graphql/mutations';
import { RadioButton } from '../inputComponents/RadioButton';
import { InputComponent } from '../inputComponents/InputComponent';
import { prizeData } from '../../pages/EditPromotion/Prizes/Prizes';
import { PrizeTableItem } from '../../types/componentTypes/PrizeTypes';
import { copyFile, getBucketName } from '../../utils/s3FileUtils';
const uniqid = require('uniqid');

interface CopyPrizeProps {
    header: string,
    modalOpenState: boolean,
    handleClose(): void,
    configPrizes: Array<prizeData>,
    copiedPrize: PrizeTableItem,
    defaultLanguage: string,
    ratioWinningEnabled: boolean,
}

function CopyPrizeModal({
    header,
    modalOpenState,
    handleClose,
    configPrizes,
    copiedPrize,
    defaultLanguage,
    ratioWinningEnabled,
}: CopyPrizeProps) {
    const [notificationState, setNotificationState] = useState(defaultNotifState);
    const [copyPrizeStep, setCopyPrizeStep] = useState(0);

    const closeModal = () => {
        handleClose();
        setCopyPrizeStep(0);
    }

    const getLocalizedPrizeName = (prize) => {
        return JSON.parse(prize.name)[defaultLanguage].split(' copy ')[0];
    }

    const handleSubmit = async (values) => {
        try {
            const copyObj = await prepareCopyObject(values);
            await API.graphql(graphqlOperation(createPrizeCatalogueTable, { input: copyObj }));

            closeModal();

            if (ratioWinningEnabled && copiedPrize?.pool_prize && copiedPrize?.winning_ratio) {
                setNotificationState({
                    open: true,
                    title: "Prize is copied successfully.",
                    content: "Ratio Winning limits are enabled and you are copying a ratio-based prize. Please add the copied prize to the Ratio Winning Limit under the promotion Limits tab if needed.",
                    level: "warning"
                });
                return;
            }

            setNotificationState({
                open: true,
                title: "Prize is copied successfully.",
                content: "",
                level: "success"
            });
        } catch (e) {
            console.error("Save failed with: ", e);
            setNotificationState({
                open: true,
                title: 'Error',
                content: `Prize creation failed! Please check console for detailed error.`,
                level: "error"
            })
        }
    }

    const getImgUrlKey = (imgUrl) => (`prizeImages/${imgUrl.split('prizeImages/')[1]}`);

    const prepareCopyObject = async (prizeObj) => {
        const selectedPrizeName = getLocalizedPrizeName(prizeObj);
        const duplicates = configPrizes.reduce((acc, configPrize) => {
            if (selectedPrizeName === getLocalizedPrizeName(configPrize))
                acc.push(selectedPrizeName);
            return acc;
        }, []);

        const newPrizeId = uniqid();
        const prizeObjCopy = { ...prizeObj };

        for (let key in prizeObjCopy) {
            if (key === 'prize_id') prizeObjCopy[key] = newPrizeId;

            if (key === 'name') prizeObjCopy[key] = JSON.stringify({ [defaultLanguage]: `${selectedPrizeName} copy ${duplicates.length}` });

            if ((key === 'start_date' || key === 'end_date') && typeof prizeObjCopy[key] !== 'number')
                prizeObjCopy[key] = Date.parse(prizeObjCopy[key]);

            if (key === 'img_url') {
                prizeObjCopy[key] = await Promise.all(prizeObjCopy[key].map(async (url) => {
                    const newUrl = url.replace(prizeObj.prize_id, newPrizeId);
                    await copyFile({
                        src: getImgUrlKey(url),
                        dest: getImgUrlKey(newUrl),
                        options: {
                            bucket: getBucketName('public')
                        }
                    })
                    return newUrl;
                }));
            }

            if (key === 'images_metadata') {
                const imageMetadata = JSON.parse(prizeObjCopy[key]);
                prizeObjCopy[key] = JSON.stringify(Object.keys(imageMetadata).map(languageCode => {
                    return { languageCode, metadata: imageMetadata[languageCode] };
                }).reduce((acc, curr) => {
                    acc[curr.languageCode] = [{
                        ...curr.metadata[0],
                        url: curr.metadata[0].url.replace(prizeObj.prize_id, newPrizeId)
                    }];
                    return acc;
                }, {}));
            }

            if (prizeObjCopy[key] === null || key === 'winning_ratio') delete prizeObjCopy[key];
        };

        return prizeObjCopy;
    }

    const cancelButton = <Button
        fullWidth
        variant='outlined'
        onClick={() => closeModal()}>
        Cancel
    </Button>

    const saveCopyButton = <Button
        fullWidth
        variant='contained'
        color='primary'
        type='submit'>
        Save Copy
    </Button>

    const nextButton = <Button
        fullWidth
        variant='contained'
        name='Next'
        endIcon={<ArrowForwardIosIcon />}
        onClick={(event) => {
            event.preventDefault();
            setCopyPrizeStep(1);
        }}>
        Next
    </Button>

    const previousButton = <Button
        fullWidth
        variant='outlined'
        name='Previous'
        startIcon={<ArrowBackIosIcon />}
        onClick={() => { setCopyPrizeStep(0) }}
    > Previous
    </Button>

    return (
        <>
            <Notification notificationState={notificationState} setNotificationState={setNotificationState} />
            <Modal
                open={modalOpenState}
                onClose={() => { closeModal() }}
                aria-labelledby="copy-prize-modal"
                aria-describedby="copy-prize-modal"
                data-testId='copy-prize-modal'
            >
                <Box className='modal_main'>
                    <IconButton onClick={() => closeModal()}
                        sx={{ position: 'absolute', top: 5, right: 5 }}>
                        <CloseIcon />
                    </IconButton>
                    <Formik
                        initialValues={{ ...copiedPrize }}
                        onSubmit={handleSubmit}
                    >
                        {formik => (
                            <form onSubmit={formik.handleSubmit}>
                                {copyPrizeStep === 0 &&
                                    <>
                                        <Box className='uploadModalHeader' marginTop={1} marginBottom={1}>
                                            <TooltipText
                                                text={header}
                                                textVariant='h6'
                                                tooltip='The prize start date is defaulted to the current date and the end date is automatically inherited as the end date of the promotion. If the timeline is different from the default values, make sure to select the applicable start and end date here. If you want to set the prize as inactive, you need to set the start date in the future.'
                                                placement={ToolTipPlacement.RightEnd}
                                            />
                                        </Box>
                                        <Typography variant="body2" align='center' marginBottom={4}>
                                            Select the prize start and end date to define the prize availability for distribution
                                        </Typography>
                                        <AllocateDates
                                            datetime={true}
                                            formikStartDate={`start_date`}
                                            formikEndDate={`end_date`}
                                            containerSpacing={2}
                                            startEndDateColumns={6}
                                            timeZoneColumns={12}
                                        />
                                    </>
                                }

                                {copyPrizeStep === 1 &&
                                    <>
                                        <TooltipText
                                            dataTestId='voucher-distribution-tooltip'
                                            styleName='prize-tooltip-text'
                                            text='Voucher Distribution'
                                            textVariant='body1'
                                            tooltip="No can be selected for digital prizes that don't require vouchers as the proof of winning in the promo, e.g. uploaded assets or hidden URLs"
                                            placement={ToolTipPlacement.RightEnd}
                                        />
                                        <RadioButton
                                            disabled={true}
                                            labelVariant='body2'
                                            formik={formik}
                                            values={[
                                                { value: 'true', label: 'Yes' },
                                                { value: 'false', label: 'No' },
                                            ]}
                                            radioGroupName={'voucher_dist'}
                                        />
                                        <InputComponent
                                            inputClassName='input-space'
                                            formik={formik}
                                            inputValue={getIn(formik.values, 'total_amount')}
                                            formikLabel={'total_amount'}
                                            title='Total Prize Quantity'
                                            titleVariant='body1'
                                        />
                                        <InputComponent
                                            inputClassName='input-space'
                                            formik={formik}
                                            inputValue={getIn(formik.values, 'total_available')}
                                            formikLabel={'total_available'}
                                            title='Available Prize Quantity'
                                            titleVariant='body1'
                                        />
                                    </>
                                }

                                <Box sx={{ marginTop: 4, marginBottom: 2 }}>
                                    <Grid container justifyContent="center" spacing={2}>
                                        <Grid item xs={6}>
                                            {copiedPrize?.voucher_dist ? cancelButton
                                                : (copyPrizeStep === 0 ? cancelButton : previousButton)}
                                        </Grid>
                                        <Grid item xs={6}>
                                            {copiedPrize?.voucher_dist ? saveCopyButton
                                                : (copyPrizeStep === 0 ? nextButton : saveCopyButton)}
                                        </Grid>
                                    </Grid>
                                </Box>
                            </form>
                        )}
                    </Formik>
                </Box>
            </Modal>
        </>
    )
}

export { CopyPrizeModal }
