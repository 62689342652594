import { useState, useEffect } from 'react';
import {notificationType} from './../../../../src/types/notifications'
import { defaultNotifState } from './../../../constants/currency-constants';
import { FriendReferralForm } from './FriendReferralForm';
import { Form, Formik } from 'formik';
import { saveConfig } from '../../../utils/s3FileUtils';
import { useParams } from "react-router-dom";
import { getCurrencies, updateCurrency } from './../../../pages/Currencies/services/api-calls'
import { Notification } from '../../../common/Notification/Notification';
import { referralRewardsValidationSchema } from './FriendReferralValidationSchema'
import { friendReferralType, boxTypeMapNames, RefProperties } from './constants/referralRewards-constants'
import useReferralInitialState from './hooks/useReferralInitialState';
import {
    CurrencyTableItem
} from '../../../types/componentTypes/CurrencyTypes';


const FriendReferral = ({config }: friendReferralType) => {
    const [notificationState, setNotificationState] = useState<notificationType>(defaultNotifState);
    const [availableCurrencies, setAvailableCurrencies] = useState<CurrencyTableItem[]>()
    const { initialState } = useReferralInitialState(config);
    let urlParams = useParams();

    const updateCurrencyObject = (inputPromoId, inputConfId, currencyObjectToUpdate) => {
       let updatedObject = {...currencyObjectToUpdate}
       updatedObject.currency_assignment = JSON.parse(updatedObject.currency_assignment )
       if(updatedObject?.currency_assignment?.hasOwnProperty(inputPromoId)) {
        updatedObject?.currency_assignment[inputPromoId].push(inputConfId)
       } else {
        Object.assign(updatedObject?.currency_assignment, {[inputPromoId]: [inputConfId]})
       }
       updatedObject.currency_assignment = JSON.stringify( updatedObject.currency_assignment);
       return updatedObject;
    }

    const updateCurrencies = async (inputCurrenciesToUpdate: string[], inputAvailableCurrencies: CurrencyTableItem[]) => {
        let objectsToUpdate: CurrencyTableItem[] = []
        inputCurrenciesToUpdate.forEach(currencyId => {
            objectsToUpdate.push(inputAvailableCurrencies.find(currObject => currObject.currency_id === currencyId))
        })
        let updatePromises = []
        objectsToUpdate.forEach(object => {
            updatePromises.push(updateCurrency(updateCurrencyObject(config.promotionId, config.configurationId, object)))
        })
        Promise.all(updatePromises).then(result => {
            return result
        })

    }

    useEffect(() => {
        const fetchAndFilterCurrencies =  (countryToDisplay: string) => {
            try {
                getCurrencies().then(fetchedCurrencies => {
                    setAvailableCurrencies((fetchedCurrencies as CurrencyTableItem[]).filter(currency => currency.country === countryToDisplay))
                })
            } catch (e) {
                console.log('Failed to fetch market relevant currencies with', e);
            }

        }
        fetchAndFilterCurrencies(config?.configurationParameters?.country)
    }, [initialState, config?.configurationParameters?.country])

    const filterRefValues = (refRewardsValues: RefProperties) => {
        const { amount, currency, limit, sendEmail = false } = refRewardsValues;
        return { amount, currency, limit, sendEmail };
    }

    const updatedConfigValues = (formikValues) => {
        let tempConfig = {...config}
        let currencyIdsToUpdate: string[] = []
        if (Object.keys(formikValues?.referralRewards).length > 0) {
            tempConfig.referralRewards = formikValues.referralRewards
            for (const key in formikValues.referralRewards) {
                tempConfig.referralRewards[key] = filterRefValues(formikValues.referralRewards[key]);
            }
        } else {
            delete tempConfig?.referralRewards
            return {updatedConfig: tempConfig, currencyIdsToUpdate: currencyIdsToUpdate}
        }

        if (!formikValues?.referrerBox && tempConfig?.referralRewards?.referrer) {
            delete tempConfig?.referralRewards.referrer
        }
        if (!formikValues?.refereeBox && tempConfig?.referralRewards?.referee) {
            delete tempConfig?.referralRewards.referee
        }

        Object.keys(boxTypeMapNames).forEach(boxName => {
            if (tempConfig?.referralRewards[boxName]) {
                tempConfig.configurationParameters.currencies.includes(tempConfig.referralRewards[boxName]?.currency) ||
                ( tempConfig.configurationParameters.currencies.push(tempConfig.referralRewards[boxName]?.currency) &&
                currencyIdsToUpdate.push(tempConfig.referralRewards[boxName]?.currency))
            }
        })
        return {updatedConfig: tempConfig, currencyIdsToUpdate: currencyIdsToUpdate}
    }
    return (
        <>
        <Formik
                initialValues={initialState}
                enableReinitialize
                validationSchema={referralRewardsValidationSchema}
                onSubmit={async (values) => {
                    try {
                        const {updatedConfig, currencyIdsToUpdate} = updatedConfigValues(values)
                        if (currencyIdsToUpdate.length) {
                            await updateCurrencies(currencyIdsToUpdate, availableCurrencies);
                        }
                        await saveConfig({ urlParams, submitData: updatedConfig, setNotificationState });
                        setNotificationState({
                            open: true,
                            title: 'Referral settings were updated successfully',
                            content: 'Referral settings were saved!',
                            level: 'success',
                        });
                    } catch (e) {
                        console.error(e);
                    }
                }}>
                    {availableCurrencies &&
                    <Form>
                    <>
                    <Notification
                    setNotificationState={setNotificationState}
                    notificationState={notificationState}
                    />
                    <FriendReferralForm
                        isEdit={true}
                        config={config}
                        nonFormikCurrList = {availableCurrencies}
                    />
                    </>
                    </Form>
                    }

            </Formik>
        </>
    )
}

export { FriendReferral }

