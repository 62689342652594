import { API, graphqlOperation } from 'aws-amplify';
import { Dispatch, SetStateAction } from 'react';
import { getAllocationRulesForConfig } from '../../../../graphql/queries';
import { getGeneralCurrencyErrorMessage } from '../../../../constants/currency-constants';
import { CurrencyTableItem } from '../../../../types/componentTypes/CurrencyTypes';
import { notificationType } from '../../../../types/notifications';
import moment from 'moment-timezone';
import { getErrorMessageFromError, removeFalsyValuesFromCurrency } from '../../../Currencies/helpers/currency-helpers';
import { updateCurrencyCurrencyTable, updateCurrencyAllocationRulesTable } from '../../../../graphql/mutations';
import { CurrencySubmitValues } from '../../../../types/componentTypes/editPromotion';
import { allocationRules } from '../../../../types/componentTypes/allocationRules'

export type IUpdatedConfig = Omit<CurrencySubmitValues, 'flowLambdas' | 'params' | 'tempParams'>;

export type SubmitExitValues = {
    updatedConfig: IUpdatedConfig
    exitToModal: boolean
}

const getMatchedCurrencies = (availableCurrencies: CurrencyTableItem[], currencyIdsToUpdate: string[]) =>
    availableCurrencies.filter((curr) => currencyIdsToUpdate.includes(curr.currency_id));

export const updateAllocRules = async (inputAllocRules: allocationRules[]) => {
    let promises = [];
    inputAllocRules.forEach(allocRule => {
        let tempUpdatedRule = allocRule
        tempUpdatedRule.deletion_timestamp = moment().unix() * 1000
        tempUpdatedRule.rule_active = false
        promises.push(API.graphql(graphqlOperation(updateCurrencyAllocationRulesTable, { input: tempUpdatedRule })))
    })
    return Promise.all(promises)
}

export const fetchRelevantAllocRules = async (inputConfigId: string) => {
    const retrieveAllocationRules = async () => {
        const queryResult: any = await API.graphql({ query: getAllocationRulesForConfig, variables: { configuration_id: inputConfigId} });
        const rulesData = queryResult.data.getAllocationRulesForConfig.items;
        return rulesData;
    }
    let allocRules: allocationRules[] = await retrieveAllocationRules()
    return allocRules.filter((rule: allocationRules) => Boolean(rule.rule_active) === true)
}



export const newCurrencyAssignment = async ({
    currValue,
    campId,
    promoId,
}: {
    currValue: CurrencyTableItem;
    campId: string;
    promoId: string;
}) => {
    const updatedCurrency = {
        ...removeFalsyValuesFromCurrency(currValue),
        currency_assignment: JSON.stringify({
            [campId]: [promoId],
        }),
    };
    return await API.graphql({ query: updateCurrencyCurrencyTable, variables: { input: updatedCurrency } });
};

const reassignPromoToCurrency = async (
    uniqueNewCurrs: string[],
    uniquePrevCurrs: string[],
    availableCurrencies: CurrencyTableItem[],
    campId: string,
    promoId: string
) => {
    const currsToUnlink = uniquePrevCurrs.filter((c) => uniqueNewCurrs.indexOf(c) === -1);
    await availableCurrencies.reduce(async (acc, currValue) => {
        const { currency_id, currency_assignment } = currValue;
        if (!currsToUnlink.includes(currency_id)) return acc;

        if (!currency_assignment) return acc;

        const parsedCurrAssignment: Record<string, string[]> = JSON.parse(currency_assignment);

        const itemsForUpdating = [...parsedCurrAssignment[campId]];

        const index = itemsForUpdating.indexOf(promoId);
        if (index > -1) itemsForUpdating.splice(index, 1);

        const updatedCurrency = {
            ...removeFalsyValuesFromCurrency(currValue),
            currency_assignment: JSON.stringify({
                ...parsedCurrAssignment,
                [campId]: itemsForUpdating,
            }),
        };

        await API.graphql({ query: updateCurrencyCurrencyTable, variables: { input: updatedCurrency } });
        return acc;
    }, Promise.resolve({}));

    const currsToLink = uniqueNewCurrs.filter((c) => uniquePrevCurrs.indexOf(c) === -1);
    await availableCurrencies.reduce(async (acc, currValue) => {
        const { currency_id, currency_assignment } = currValue;
        if (!currsToLink.includes(currency_id)) return acc;

        if (!currency_assignment) {
            await newCurrencyAssignment({ promoId, campId, currValue });
            return acc;
        }

        const parsedCurrAssignment: Record<string, string[]> = JSON.parse(currency_assignment);
        const itemsForUpdating = !parsedCurrAssignment[campId] ? [] : [...parsedCurrAssignment[campId]];
        itemsForUpdating.push(promoId);

        const updatedCurrency = {
            ...removeFalsyValuesFromCurrency(currValue),
            currency_assignment: JSON.stringify({
                ...parsedCurrAssignment,
                [campId]: [...new Set(itemsForUpdating)],
            }),
        };

        await API.graphql({ query: updateCurrencyCurrencyTable, variables: { input: updatedCurrency } });
        return acc;
    }, Promise.resolve({}));
};

export const addPromoToCurrency = async (
    uniqueNewCurrs: string[],
    uniquePrevCurrs: string[],
    availableCurrencies: CurrencyTableItem[],
    campId: string,
    promoId: string
) => {
    const currencyIdsToUpdate = uniqueNewCurrs.filter((curr) => !uniquePrevCurrs.includes(curr));

    return getMatchedCurrencies(availableCurrencies, currencyIdsToUpdate).reduce(async (acc, currValue) => {
        const { currency_assignment } = currValue;

        if (!currency_assignment) {
            await newCurrencyAssignment({ promoId, campId, currValue });
            return acc;
        }

        const parsedCurrAssignment: Record<string, string[]> = JSON.parse(currency_assignment);
        const itemsForUpdating = !parsedCurrAssignment[campId] ? [] : [...parsedCurrAssignment[campId]];
        itemsForUpdating.push(promoId);

        const updatedCurrency = {
            ...removeFalsyValuesFromCurrency(currValue),
            currency_assignment: JSON.stringify({
                ...parsedCurrAssignment,
                [campId]: [...new Set(itemsForUpdating)],
            }),
        };

        await API.graphql({ query: updateCurrencyCurrencyTable, variables: { input: updatedCurrency } });

        return acc;
    }, Promise.resolve({}));
};

const removePromoFromCurrency = async (
    uniqueNewCurrs: string[],
    uniquePrevCurrs: string[],
    availableCurrencies: CurrencyTableItem[],
    campId: string,
    promoId: string
) => {
    const currencyIdsToUpdate = uniquePrevCurrs.filter((curr) => !uniqueNewCurrs.includes(curr));

    return getMatchedCurrencies(availableCurrencies, currencyIdsToUpdate).reduce(async (acc, currValue) => {
        const { currency_assignment } = currValue;

        const parsedCurrAssignment: Record<string, string[]> = JSON.parse(currency_assignment);

        if (!parsedCurrAssignment[campId]) {
            console.error({
                message: `No currency assignment found for currency: ${currValue.currency_id} with campaignId: ${campId}`,
                data: { campaignId: campId, currencyId: currValue.currency_id, promotionId: promoId },
            });
            return acc;
        }

        const itemsForUpdating =
            parsedCurrAssignment &&
            [...parsedCurrAssignment[campId]].filter((currentPromoId) => currentPromoId !== promoId);

        const updatedCurrency = {
            ...removeFalsyValuesFromCurrency(currValue),
            ...(parsedCurrAssignment && {
                currency_assignment: JSON.stringify({
                    ...parsedCurrAssignment,
                    ...(itemsForUpdating && { [campId]: itemsForUpdating }),
                }),
            }),
        };

        await API.graphql({ query: updateCurrencyCurrencyTable, variables: { input: updatedCurrency } });

        return acc;
    }, Promise.resolve({}));
};

export const updateCurrencies = async ({
    availableCurrencies,
    inputUpdatedConfig,
    previousCurrencies,
    currentConfigCurrencies,
    setNotificationState,
    setCurrentConfigCurrencies,
}: {
    currentConfigCurrencies: string[];
    previousCurrencies: string[];
    inputUpdatedConfig : IUpdatedConfig;
    availableCurrencies: CurrencyTableItem[];
    setCurrentConfigCurrencies: Dispatch<SetStateAction<string[]>>;
    setNotificationState: Dispatch<SetStateAction<notificationType>>;
}) => {
    const campId = inputUpdatedConfig.promotionId;
    const promoId = inputUpdatedConfig.configurationId;

    const uniquePrevCurrs = Array.from(
        new Set(currentConfigCurrencies?.length ? currentConfigCurrencies : previousCurrencies)
    );
    const uniqueNewCurrs = Array.from(new Set(inputUpdatedConfig.configurationParameters.currencies));

    try {
        if (uniquePrevCurrs.toString() === uniqueNewCurrs.toString()) return console.log('nothing to update');

        // this is a reassign action
        if (
            uniquePrevCurrs.toString() !== uniqueNewCurrs.toString() &&
            uniquePrevCurrs.length === uniqueNewCurrs.length
        )
            return await reassignPromoToCurrency(uniqueNewCurrs, uniquePrevCurrs, availableCurrencies, campId, promoId);

        // this is a push action
        if (uniquePrevCurrs.length < uniqueNewCurrs.length)
            return await addPromoToCurrency(uniqueNewCurrs, uniquePrevCurrs, availableCurrencies, campId, promoId);

        // this is a filter action
        if (uniqueNewCurrs.length < uniquePrevCurrs.length)
            return await removePromoFromCurrency(uniqueNewCurrs, uniquePrevCurrs, availableCurrencies, campId, promoId);
    } catch (error) {
        console.error(error);
        setNotificationState(getGeneralCurrencyErrorMessage(getErrorMessageFromError(error)));
    } finally {
        setCurrentConfigCurrencies(uniqueNewCurrs);
    }
};
